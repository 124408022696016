import { useMemo } from 'react';
import styles from './QuestionMenu.module.scss';
import { ReactComponent as CheckIcon } from '../icons/check.svg';
// import { ReactComponent as PlayCircleFilled } from '../icons/play_circle_filled.svg';
import { ReactComponent as ExpendIcon } from '../icons/arrow_down.svg';
import { ReactComponent as LeftArrowIcon } from '../icons/previous.svg';
import { ReactComponent as RightArrowIcon } from '../icons/next.svg';
import { Question, Chapter as ChapterType, AnswersResponse } from '../../types';
import { ReactComponent as PlayFilledIcon } from '../icons/play_circle_filled.svg';
import { useNavigation } from '../../NavigationContext';
import ProgressBar from 'components/Checklist/QuestionSelector/ProgressBar';

type ResponseWithRulesApplied = {
	chapters: {
		questions: Question[];
		checklistId: string;
		chapterId: string;
		title: string;
		index: number;
		context?: string;
		contextMedia?: string[];
	}[];
	title: string;
	_id: string;
	coverImage: string;
};

export default function QuestionMenu({
	data,
	values,
	setIsAutoNavigationDisabled,
}: {
	data: ResponseWithRulesApplied;
	values: AnswersResponse;
	setIsAutoNavigationDisabled: (isAutoNavigationDisabled: boolean) => void;
}) {
	const { navigation, setNavigation } = useNavigation();
	const chapter = useMemo(
		() =>
			data.chapters?.find(
				(chapter) => chapter.chapterId === navigation.split('-')[1]
			),
		[data, navigation]
	);
	return (
		<div className={styles.questionMenu}>
			<div className={styles.title}>QUESTIONS</div>
			<div className={styles.questions}>
				{chapter &&
					chapter.questions.map((question, index) => (
						<div
							key={index}
							onClick={() => {
								setNavigation(
									`question-${chapter.chapterId}-${question.questionId}`
								);
								// close && close();
							}}
							className={`${styles.question} ${
								navigation ===
								`question-${chapter.chapterId}-${question.questionId}`
									? styles.selected
									: ''
							}`}
						>
							<div className={styles.questionActions}>
								{values[question.questionId] ? (
									<div className={styles.check}>
										<CheckIcon />
									</div>
								) : (
									<PlayFilledIcon />
								)}
							</div>
							<div className={styles.questionText}>
								<div className={styles.button}>{question.title}</div>
								<div className={styles.tabBarItems}>
									{question.type === 'multipleSelect'
										? 'Choix multiples'
										: question.type === 'singleSelect'
										? 'Choix simple'
										: question.type === 'text'
										? 'Texte'
										: question.type === 'number'
										? 'Nombre'
										: question.type === 'date'
										? 'Date'
										: question.type === 'media'
										? 'Média'
										: question.type === 'email'
										? 'Email'
										: 'Non défini'}
								</div>
							</div>
						</div>
					))}
			</div>
			{/* {data?.chapters
				?.sort((a: ChapterType, b: ChapterType) => a.index - b.index)
				?.map((chapter: ChapterType) => (
					<div
						onClick={() =>
							setNavigation(
								`question-${chapter.chapterId}-${chapter.questions[0].questionId}`
							)
						}
						className={styles.spacedFlex}
						key={chapter.chapterId}
					>
						<div className={styles.column}>
							<div className={styles.button}>{chapter.title}</div>
							<div className={styles.tabBarItems}>
								Chapitre{' '}
								{data.chapters.findIndex(
									(thisChapter: ChapterType) =>
										thisChapter.chapterId === chapter.chapterId
								) + 1}
							</div>
						</div>
						{chapter.questions?.every(
							(question) => values?.[question.questionId]
						) ? (
							<CheckIcon />
						) : (
							<PlayCircleFilled />
						)}
					</div>
				))} */}
			<div className={styles.chapterSelector}>
				<div className={styles.spacedFlex}>
					<div className={styles.title}>
						Chapitre{' '}
						{data.chapters.findIndex(
							(chapter: ChapterType) =>
								chapter.chapterId === navigation.split('-')[1]
						) + 1}
						/{data.chapters.length}
						<ExpendIcon />
					</div>
					<div className={styles.flex}>
						<button
							className={styles.navButtons}
							onClick={() => {
								const sortedChapters = data.chapters.sort(
									(a: ChapterType, b: ChapterType) => a.index - b.index
								);
								const currentChapterIndex = sortedChapters.findIndex(
									(chapter: ChapterType) =>
										chapter.chapterId === navigation.split('-')[1]
								);
								if (currentChapterIndex > 0) {
									setNavigation(
										`question-${
											sortedChapters[currentChapterIndex - 1].chapterId
										}-${
											sortedChapters[currentChapterIndex - 1].questions[0]
												.questionId
										}`
									);
									setIsAutoNavigationDisabled(true);
								}
							}}
						>
							<LeftArrowIcon />
						</button>
						<button
							className={styles.navButtons}
							onClick={() => {
								const sortedChapters = data.chapters.sort(
									(a: ChapterType, b: ChapterType) => a.index - b.index
								);
								const currentChapterIndex = sortedChapters.findIndex(
									(chapter: ChapterType) =>
										chapter.chapterId === navigation.split('-')[1]
								);
								if (currentChapterIndex < sortedChapters.length - 1) {
									setIsAutoNavigationDisabled(true);
									setNavigation(
										`question-${
											sortedChapters[currentChapterIndex + 1].chapterId
										}-${
											sortedChapters[currentChapterIndex + 1].questions[0]
												.questionId
										}`
									);
								}
							}}
						>
							<RightArrowIcon />
						</button>
					</div>
				</div>
				<div className={styles.content}>
					{chapter?.title}
					<span>
						{chapter?.context
							? chapter.context
							: 'Aucune description du chapitre'}
					</span>
					<div style={{ width: '100%' }}>
						{chapter && (
							<ProgressBar chapter={chapter} answers={values} noPadding />
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
