import React, {useState, useRef, useEffect, useMemo, useCallback} from 'react';
import {Question as QuestionType, Rule as RuleType} from '../../../types';
import {ReactComponent as PlusIcon} from '../../icons/plus.svg';
import {ReactComponent as EmptyFlagIcon} from '../../icons/empty_flag.svg';
import {ReactComponent as FilledFlagIcon} from '../../icons/filled_flag.svg';
import {ReactComponent as DeleteIcon} from '../../icons/delete.svg';
import styles from './SingleSelect.module.scss';
import DraggableList from '../../DraggableList';
import Switch from '../../Switch/Switch';

export default function SingleSelectSettings({
	question,
	updateQuestion,
	settings = false,
}: {
	question: QuestionType;
	updateQuestion: (
		question: QuestionType,
		updates: Partial<QuestionType>
	) => void;
	settings?: boolean;
}) {
	const optionRefs = useRef<(HTMLTextAreaElement | null)[]>([]);
	const [localOptions, setLocalOptions] = useState<string[]>(
		question.singleSelect?.options || []
	);
	const [heights, setHeights] = useState<number[]>([]);
	const [isAddingNewOption, setIsAddingNewOption] = useState<boolean>(false);
	const firstRender = useRef<boolean>(true);

	const debounceDelay = 300;
	const timeoutId = useRef<NodeJS.Timeout | null>(null);

	const handleChange = (
		e: React.ChangeEvent<HTMLTextAreaElement>,
		index: number
	) => {
		const newValue = e.target.value;
		const newOptions = [...localOptions];
		newOptions[index] = newValue;
		setLocalOptions(newOptions);

		if (timeoutId.current) {
			clearTimeout(timeoutId.current);
		}

		timeoutId.current = setTimeout(() => {
			updateQuestion(question, {
				singleSelect: {
					options: newOptions,
					criticalOptions: question.singleSelect?.criticalOptions || [],
					hasCriticalOptions:
						question.singleSelect?.hasCriticalOptions || false,
					other: question.singleSelect?.other || false,
				},
			});
		}, debounceDelay);

		adjustHeight(e.target);
	};

	const handleKeyDown = (
		e: React.KeyboardEvent<HTMLTextAreaElement>,
		index: number
	) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			handleAddOption();
		}
	};

	const handleReorderOptions = (newOrder: number[]) => {
		let newOptions = newOrder.map((index) => localOptions[index] || '');
		newOptions = newOptions.filter((option) => option !== '');
		setLocalOptions(newOptions);
		updateQuestion(question, {
			singleSelect: {
				options: newOptions,
				criticalOptions: question.singleSelect?.criticalOptions || [],
				hasCriticalOptions: question.singleSelect?.hasCriticalOptions || false,
				other: question.singleSelect?.other || false,
			},
		});
	};

	const handleAddOption = useCallback(() => {
		if (!localOptions.includes('')) {
			setIsAddingNewOption(true);
			updateQuestion(question, {
				singleSelect: {
					options: [...localOptions, ''],
					criticalOptions: question.singleSelect?.criticalOptions || [],
					hasCriticalOptions:
						question.singleSelect?.hasCriticalOptions || false,
					other: question.singleSelect?.other || false,
				},
			});
		}
	}, [localOptions, question, updateQuestion]);

	const adjustHeight = (textarea: HTMLTextAreaElement | null) => {
		if (textarea) {
			textarea.style.height = '0px';
			textarea.style.height = `${textarea.scrollHeight}px`;
		}
	};

	const order = useMemo(() => {
		const newOrder = question.singleSelect?.options.map((_, i) => i) || [];
		if (isAddingNewOption) {
			newOrder.push(newOrder.length);
		}
		return newOrder;
	}, [question.singleSelect?.options, isAddingNewOption]);

	useEffect(() => {
		if (localOptions.length === 0 && firstRender.current) {
			firstRender.current = false;
			handleAddOption();
		}
	}, [localOptions, handleAddOption]);

	useEffect(() => {
		setLocalOptions(question.singleSelect?.options || []);
	}, [question]);

	useEffect(() => {
		setTimeout(() => {
			if (question.singleSelect) {
				const newHeights: number[] = question.singleSelect.options.map(
					(_, index) => {
						return optionRefs.current[index]?.offsetHeight || 40;
					}
				);
				setHeights(newHeights);
			}
		}, 0);
	}, [question.singleSelect, isAddingNewOption, localOptions]);

	useEffect(() => {
		optionRefs.current.forEach((textarea) => adjustHeight(textarea));
	}, [localOptions]);

	return (
		<div className={`${styles.settings} ${settings ? styles.noGap : ''}`}>
			{!settings ? (
				<>
					{question.singleSelect?.options?.length ? (
						question.singleSelect?.options.length > 0 ? (
							<div className={styles.choice}>Choix de réponse</div>
						) : null
					) : null}

					{localOptions.length >= 0 && (
						<DraggableList
							items={[
								...localOptions.map((option, index) => (
									<div className={styles.flex} key={index}>
										<textarea
											ref={(el) => {
												if (el) {
													optionRefs.current[index] = el;
													if (el.value === '') {
														el.focus();
													}

													adjustHeight(el);
												}
											}}
											value={option || ''}
											onChange={(e) => handleChange(e, index)}
											onKeyDown={(e) => handleKeyDown(e, index)}
											onBlur={() => {
												if (option === '') {
													const newOptions = [...localOptions];
													newOptions.splice(index, 1);
													updateQuestion(question, {
														singleSelect: {
															options: newOptions,
															criticalOptions:
																question.singleSelect?.criticalOptions || [],
															hasCriticalOptions:
																question.singleSelect?.hasCriticalOptions ||
																false,
															other: question.singleSelect?.other || false,
														},
													});
												}
											}}
											className={styles.textarea}
										/>
										<button
											className={`${styles.deleteButton} ${
												question.singleSelect?.hasCriticalOptions
													? styles.withFlag
													: ''
											}`}
											onClick={() => {
												const newOptions = [...localOptions];
												newOptions.splice(index, 1);

												const newRules: RuleType[] =
													question.rules?.map((rule) => {
														const newRule = {...rule};
														const value =
															newRule.value?.filter(
																(value) => value !== option
															) || [];
														newRule.value = value || [];
														return newRule;
													}) || [];

												setLocalOptions(newOptions);
												updateQuestion(question, {
													singleSelect: {
														options: newOptions,
														criticalOptions:
															question.singleSelect?.criticalOptions || [],
														hasCriticalOptions:
															question.singleSelect?.hasCriticalOptions ||
															false,
														other: question.singleSelect?.other || false,
													},
													rules: newRules,
												});
											}}
										>
											<DeleteIcon />
										</button>
										{question.singleSelect?.hasCriticalOptions && (
											<div
												onClick={() => {
													const newCriticalOptions = [
														...(question.singleSelect?.criticalOptions || []),
													];
													if (newCriticalOptions.includes(option)) {
														newCriticalOptions.splice(
															newCriticalOptions.indexOf(option),
															1
														);
													} else {
														newCriticalOptions.push(option);
													}
													updateQuestion(question, {
														singleSelect: {
															options: [...localOptions],
															criticalOptions: newCriticalOptions,
															hasCriticalOptions: true,
															other: question.singleSelect?.other || false,
														},
													});
												}}
											>
												{question.singleSelect?.criticalOptions?.includes(
													option
												) ? (
													<FilledFlagIcon />
												) : (
													<EmptyFlagIcon />
												)}
											</div>
										)}
									</div>
								)),
							]}
							gap={10}
							order={order}
							setOrder={(newOrder) => handleReorderOptions(newOrder)}
							heights={heights}
							disableInitialAnimation={true}
							translateY={0.7}
						/>
					)}
					<div className={styles.addContainer}>
						<button
							className={styles.greyButton}
							onClick={() => {
								handleAddOption();
							}}
						>
							<PlusIcon />
							Ajouter un choix de réponse
						</button>
					</div>
				</>
			) : (
				<>
					<div
						onClick={() => {
							updateQuestion(question, {
								singleSelect: {
									options: [...localOptions],
									criticalOptions: question.singleSelect?.criticalOptions || [],
									hasCriticalOptions:
										question.singleSelect?.hasCriticalOptions || false,
									other: !question.singleSelect?.other,
								},
							});
						}}
						className={styles.lateralPannelSection}
					>
						<div className={styles.spacedFlex}>
							<div className={styles.button}>RÉPONSE AUTRE</div>

							<Switch isOn={question.singleSelect?.other} />
						</div>
						<p>Permet de fournir une réponse ouverte grâce à un champ Texte</p>
					</div>

					<div
						onClick={() => {
							updateQuestion(question, {
								singleSelect: {
									options: [...localOptions],
									criticalOptions: question.singleSelect?.criticalOptions || [],
									hasCriticalOptions:
										!question.singleSelect?.hasCriticalOptions,
									other: question.singleSelect?.other || false,
								},
							});
						}}
						className={styles.lateralPannelSection}
					>
						<div className={styles.spacedFlex}>
							<div className={styles.button}>VALEURS CRITIQUES</div>
							<Switch isOn={question.singleSelect?.hasCriticalOptions} />
						</div>
						<p>
							Permet d’indiquer les réponses problématiques. Utiliser le bouton
							adjacent pour indiquer quel choix représente une réponse critique.
							<br />
							<br />
							Si cette ou ces réponses sont données, elles seront mises en avant
							lorsque la Checklist aura été complétée.
						</p>
					</div>
				</>
			)}
		</div>
	);
}
