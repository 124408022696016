import React, { useState, useRef, useEffect } from 'react';
import styles from './TitleInput.module.scss';

const DEBOUNCE_DELAY = 300;

interface TitleInputProps {
	id?: string;
	mode?: 'simple' | 'context' | 'title' | 'comment';
	title: string;
	onChange: (value: string) => void;
	placeholder?: string;
}

const TitleInput: React.FC<TitleInputProps> = ({
	id,
	mode = 'simple',
	title,
	onChange,
	placeholder = 'Saisir le texte',
}) => {
	const [localTitle, setLocalTitle] = useState(title);
	const isTyping = useRef(false);
	const timeoutId = useRef<NodeJS.Timeout | null>(null);
	const questionTitleRef = useRef<HTMLTextAreaElement>(null);
	let clickTimer: NodeJS.Timeout | null = null;
	const shortClickDuration = 200;

	useEffect(() => {
		if (!isTyping.current) {
			setLocalTitle(title);
		}
	}, [title]);

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const newValue = e.target.value;
		setLocalTitle(newValue);
		isTyping.current = true;

		if (timeoutId.current) {
			clearTimeout(timeoutId.current);
		}

		timeoutId.current = setTimeout(() => {
			onChange(newValue);
			isTyping.current = false;
		}, DEBOUNCE_DELAY);
	};

	useEffect(() => {
		const textarea = questionTitleRef.current;
		if (textarea) {
			textarea.style.height = '0px';
			textarea.style.height = `${textarea.scrollHeight}px`;
		}
	}, [localTitle, questionTitleRef]);

	useEffect(() => {
		if (mode === 'title' && localTitle === '') {
			questionTitleRef.current?.focus();
		}
	}, [mode, localTitle]);

	return (
		<textarea
			id={id}
			ref={questionTitleRef}
			className={
				mode === 'context'
					? localTitle === ''
						? styles.emptyContextInput
						: styles.contextInput
					: styles[mode]
			}
			value={localTitle}
			onChange={handleChange}
			placeholder={placeholder}
			onMouseDown={() => {
				clickTimer = setTimeout(() => {
					clickTimer = null;
				}, shortClickDuration);
			}}
			onMouseUp={(e) => {
				if (clickTimer) {
					clearTimeout(clickTimer);
					questionTitleRef.current?.setSelectionRange(
						0,
						questionTitleRef.current.value.length
					);
				}
			}}
		/>
	);
};

export default TitleInput;
