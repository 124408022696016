import { useState, useRef, useEffect } from 'react';
import { ReactComponent as PlusIcon } from '../icons/plus.svg';
import { ReactComponent as ArrowDownIcon } from '../icons/arrow_down.svg';
import { ReactComponent as SingleSelectIcon } from '../icons/single.svg';
import { ReactComponent as MultipleSelectIcon } from '../icons/multiple.svg';
import {
	Checklist as ChecklistType,
	Chapter as ChapterType,
	Question as QuestionType,
} from '../../types';
import styles from './Rules.module.scss';
import Switch from '../Switch/Switch';

const Rule = ({
	thisQuestion,
	updateQuestion,
	checklist,
	rule,
	index,
}: {
	thisQuestion: QuestionType;
	updateQuestion: (
		question: QuestionType,
		data: {
			rules: {
				condition: boolean;
				value: string[];
				action: boolean;
				targetQuestionIds: string[];
			}[];
		}
	) => void;
	checklist: ChecklistType;
	rule: {
		condition: boolean;
		value: string[];
		action: boolean;
		targetQuestionIds: string[];
	};
	index: number;
}) => {
	const [openIfPopup, setOpenIfPopup] = useState(false);
	const [openThenPopup, setOpenThenPopup] = useState(false);
	const ifPopupRef = useRef<HTMLDivElement>(null);
	const thenPopupRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClick = (e: MouseEvent) => {
			if (
				ifPopupRef.current &&
				!ifPopupRef.current.contains(e.target as Node)
			) {
				setOpenIfPopup(false);
			}
			if (
				thenPopupRef.current &&
				!thenPopupRef.current.contains(e.target as Node)
			) {
				setOpenThenPopup(false);
			}
		};
		document.addEventListener('mousedown', handleClick);
		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	const handleClickOnQuestion = (question: QuestionType) => {
		let newRule;
		if (rule.targetQuestionIds.includes(question.questionId)) {
			newRule = {
				condition: rule.condition,
				value: [...rule.value],
				action: rule.action,
				targetQuestionIds: rule.targetQuestionIds.filter(
					(id) => id !== question.questionId
				),
			};
		} else {
			newRule = {
				condition: rule.condition,
				value: [...rule.value],
				action: rule.action,
				targetQuestionIds: [...rule.targetQuestionIds],
			};
			newRule.targetQuestionIds.push(question.questionId);
		}
		const rules = [...(thisQuestion.rules || [])];
		rules[index] = newRule;
		updateQuestion(thisQuestion, {
			rules,
		});
	};

	const handleClickOnChapter = (chapter: ChapterType) => {
		if (!chapter.questions) return;

		const currentChapterIndex = checklist.chapters.findIndex(
			(c: ChapterType) => c.chapterId === thisQuestion.chapterId
		);

		const filteredQuestions = chapter.questions.filter((question) => {
			const isPreviousChapter =
				checklist.chapters.findIndex(
					(c: ChapterType) => c.chapterId === chapter.chapterId
				) < currentChapterIndex;
			const isPreviousQuestion =
				thisQuestion.chapterId === chapter.chapterId &&
				checklist.chapters.findIndex(
					(c: ChapterType) => c.chapterId === chapter.chapterId
				) === currentChapterIndex &&
				thisQuestion.index > question.index;
			const isThisQuestion = thisQuestion.questionId === question.questionId;

			return !isPreviousChapter && !isPreviousQuestion && !isThisQuestion;
		});

		const filteredQuestionIds = filteredQuestions.map((q) => q.questionId);

		let newRule;
		if (
			filteredQuestionIds.every((id) => rule.targetQuestionIds.includes(id))
		) {
			newRule = {
				condition: rule.condition,
				value: [...rule.value],
				action: rule.action,
				targetQuestionIds: rule.targetQuestionIds.filter(
					(id) => !filteredQuestionIds.includes(id)
				),
			};
		} else {
			newRule = {
				condition: rule.condition,
				value: [...rule.value],
				action: rule.action,
				targetQuestionIds: [
					...rule.targetQuestionIds,
					...filteredQuestionIds,
				].filter((id, index, self) => self.indexOf(id) === index),
			};
		}

		const rules = [...(thisQuestion.rules || [])];
		rules[index] = newRule;
		updateQuestion(thisQuestion, {
			rules,
		});
	};

	const handleNavigation = (value: boolean) => {
		const rules = [...(thisQuestion.rules || [])];
		if (value) {
			rules[index] = {
				condition: !rule.condition,
				value: [...rule.value],
				action: rule.action,
				targetQuestionIds: [...rule.targetQuestionIds],
			};
		} else {
			rules[index] = {
				condition: rule.condition,
				value: [...rule.value],
				action: !rule.action,
				targetQuestionIds: [...rule.targetQuestionIds],
			};
		}

		updateQuestion(thisQuestion, {
			rules,
		});
	};

	const handleClickOnOption = (option: string) => {
		let newRule;
		if (rule.value.includes(option)) {
			newRule = {
				condition: rule.condition,
				value: rule.value.filter((value) => value !== option),
				action: rule.action,
				targetQuestionIds: [...rule.targetQuestionIds],
			};
		} else {
			newRule = {
				condition: rule.condition,
				value: [...rule.value, option],
				action: rule.action,
				targetQuestionIds: [...rule.targetQuestionIds],
			};
		}
		const rules = [...(thisQuestion.rules || [])];
		rules[index] = newRule;
		updateQuestion(thisQuestion, {
			rules,
		});
	};

	const handleDeleteRule = () => {
		const rules = [...(thisQuestion.rules || [])];
		rules.splice(index, 1);
		updateQuestion(thisQuestion, {
			rules,
		});
	};

	return (
		<div>
			<div className={styles.header}>
				Condition #{index + 1}
				<button onClick={handleDeleteRule} className={styles.delete}>
					Supprimer
				</button>
			</div>
			<div className={styles.content}>
				<div className={styles.condition}>
					Sélectionner parmi les choix de réponse possibles
					<button
						onClick={() => setOpenIfPopup(true)}
						className={styles.conditionButton}
					>
						{rule.condition ? 'Si la réponse est' : "Si la réponse n'est pas"}
						<ArrowDownIcon />
					</button>
				</div>
				{rule.value.length > 0 && (
					<div className={styles.conditions}>
						{thisQuestion.type === 'singleSelect' ? (
							<SingleSelectIcon />
						) : (
							<MultipleSelectIcon />
						)}
						{rule.value.map((value, valueIndex) =>
							valueIndex === 0 ? value : ` ; ${value}`
						)}
					</div>
				)}
				{openIfPopup && (
					<div className={styles.rulesPopup} ref={ifPopupRef}>
						<div className={styles.header}>
							<h5>Réponse</h5>
							<div className={styles.spacedFlex}>
								<button
									onClick={() => handleNavigation(true)}
									className={`${styles.secondaryButton} ${
										rule.condition ? styles.selectedSecondary : ''
									}`}
								>
									Si la réponse est
								</button>
								<button
									onClick={() => handleNavigation(true)}
									className={`${styles.secondaryButton} ${
										!rule.condition ? styles.selectedSecondary : ''
									}`}
								>
									Si la réponse n'est pas
								</button>
							</div>
						</div>
						<ul>
							{(thisQuestion.type === 'singleSelect'
								? thisQuestion.singleSelect?.options || []
								: thisQuestion.multipleSelect?.options || []
							)?.map((option: string, optionIndex) => (
								<li
									key={optionIndex}
									onClick={() => handleClickOnOption(option)}
									className={optionIndex === 0 ? styles.noBorder : ''}
								>
									{option}
									<Switch isOn={rule.value?.includes(option)} />
								</li>
							))}
						</ul>
					</div>
				)}
				Alors
				<div className={styles.condition}>
					Sélectionner les questions ou chapitres à afficher ou masquer
					<button
						onClick={() => setOpenThenPopup(true)}
						className={styles.conditionButton}
					>
						<div className={styles.flex}>
							{rule.action ? 'Afficher' : 'Ne pas afficher'}
							<span>
								{(() => {
									const validQuestions = checklist.chapters?.flatMap(
										(chapter: ChapterType) =>
											chapter.questions?.filter((question) => {
												const isPreviousChapter =
													checklist.chapters.findIndex(
														(c: ChapterType) =>
															c.chapterId === chapter.chapterId
													) <
													checklist.chapters.findIndex(
														(c: ChapterType) =>
															c.chapterId === thisQuestion.chapterId
													);

												const isPreviousQuestion =
													thisQuestion.chapterId === chapter.chapterId &&
													thisQuestion.index > question.index;

												const isThisQuestion =
													thisQuestion.questionId === question.questionId;

												return (
													!isPreviousChapter &&
													!isPreviousQuestion &&
													!isThisQuestion
												);
											})
									);

									const filteredTargetQuestionIds = validQuestions?.filter(
										(question) =>
											question &&
											rule.targetQuestionIds?.includes(question.questionId)
									);

									const count = filteredTargetQuestionIds?.length || 0;

									return count > 0
										? `(${count} question${count > 1 ? 's' : ''})`
										: '';
								})()}
							</span>
						</div>
						<ArrowDownIcon />
					</button>
				</div>
				{openThenPopup && (
					<div className={styles.rulesPopup} ref={thenPopupRef}>
						<div className={styles.header}>
							<h5>Conséquences</h5>
							<h6>Sélectionner les questions ou chapitres à afficher</h6>
							<div className={styles.spacedFlex}>
								<button
									onClick={() => handleNavigation(false)}
									className={`${styles.secondaryButton} ${
										rule.action ? styles.selectedSecondary : ''
									}`}
								>
									Afficher
								</button>
								<button
									onClick={() => handleNavigation(false)}
									className={`${styles.secondaryButton} ${
										!rule.action ? styles.selectedSecondary : ''
									}`}
								>
									Ne pas afficher
								</button>
							</div>
						</div>
						<ul>
							{checklist.chapters
								?.filter((chapter: ChapterType) => {
									const isPreviousChapter =
										checklist.chapters.findIndex(
											(c: ChapterType) => c.chapterId === chapter.chapterId
										) <
										checklist.chapters.findIndex(
											(c: ChapterType) => c.chapterId === thisQuestion.chapterId
										);
									return !isPreviousChapter;
								})
								?.map((chapter: ChapterType) => (
									<div key={chapter.chapterId}>
										<li
											onClick={() => handleClickOnChapter(chapter)}
											className={`${styles.chapter} ${
												checklist.chapters.findIndex(
													(c: ChapterType) => c.chapterId === chapter.chapterId
												) === 0
													? styles.noBorder
													: ''
											}`}
										>
											<div className={styles.column}>
												<span>{chapter.title}</span>
												Chapitre{' '}
												{checklist.chapters.findIndex(
													(c: ChapterType) => c.chapterId === chapter.chapterId
												) + 1}
											</div>{' '}
											<Switch
												isOn={(() => {
													const test = chapter.questions?.filter((question) => {
														const isPreviousChapter =
															checklist.chapters.findIndex(
																(c: ChapterType) =>
																	c.chapterId === chapter.chapterId
															) <
															checklist.chapters.findIndex(
																(c: ChapterType) =>
																	c.chapterId === thisQuestion.chapterId
															);

														const isPreviousQuestion =
															thisQuestion.chapterId === chapter.chapterId &&
															thisQuestion.index > question.index;

														const isThisQuestion =
															thisQuestion.questionId === question.questionId;

														return (
															!isPreviousChapter &&
															!isPreviousQuestion &&
															!isThisQuestion
														);
													});

													return (
														test?.every((question) =>
															rule.targetQuestionIds?.includes(
																question.questionId
															)
														) && test?.length > 0
													);
												})()}
											/>
										</li>
										{chapter.questions
											?.filter((question) => {
												const isPreviousChapter =
													checklist.chapters.findIndex(
														(c: ChapterType) =>
															c.chapterId === chapter.chapterId
													) <
													checklist.chapters.findIndex(
														(c: ChapterType) =>
															c.chapterId === thisQuestion.chapterId
													);
												const isPreviousQuestion =
													thisQuestion.chapterId === chapter.chapterId &&
													thisQuestion.index > question.index;

												const isThisQuestion =
													thisQuestion.questionId === question.questionId;

												return (
													!isPreviousChapter &&
													!isPreviousQuestion &&
													!isThisQuestion
												);
											})
											?.map((question: QuestionType) => (
												<li
													onClick={() => handleClickOnQuestion(question)}
													key={question.questionId}
												>
													{question.title}
													<Switch
														isOn={rule.targetQuestionIds?.includes(
															question.questionId
														)}
													/>
												</li>
											))}
									</div>
								))}
						</ul>
					</div>
				)}
			</div>
		</div>
	);
};

export default function Rules({
	checklist,
	question,
	updateQuestion,
}: {
	checklist: ChecklistType;
	question: QuestionType;
	updateQuestion: (
		question: QuestionType,
		data: {
			rules: {
				condition: boolean;
				value: string[];
				action: boolean;
				targetQuestionIds: string[];
			}[];
		}
	) => void;
}) {
	return (
		<div className={styles.rules}>
			{question.rules?.map((rule, index) => (
				<Rule
					thisQuestion={question}
					updateQuestion={updateQuestion}
					checklist={checklist}
					rule={rule}
					index={index}
					key={index}
				/>
			))}
			<button
				onClick={() =>
					updateQuestion(question, {
						rules: [
							...(question.rules || []),
							{
								condition: true,
								value: [],
								action: true,
								targetQuestionIds: [],
							},
						],
					})
				}
				className={styles.greyButton}
			>
				<PlusIcon />
				Ajouter une condition
			</button>
		</div>
	);
}
