import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../config';

const baseUrl = config.baseUrl;

export const userApi = createApi({
	reducerPath: 'authApi',
	baseQuery: fetchBaseQuery({ baseUrl }),
	endpoints: (builder) => ({
		signup: builder.mutation({
			query: ({ email, password, firstName, lastName }) => ({
				url: '/auth/signup',
				method: 'POST',
				body: { email, password, firstName, lastName },
			}),
		}),
		login: builder.mutation({
			query: ({ email, password }) => ({
				url: '/auth/login',
				method: 'POST',
				body: { email, password },
			}),
		}),
		me: builder.query({
			query: () => ({
				url: '/auth/me',
				method: 'GET',
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			}),
		}),
	}),
});

export const { useSignupMutation, useLoginMutation, useMeQuery } = userApi;
