import { useState, useEffect } from 'react';
import './App.scss';
import Home from './components/Home/Home';
import Folder from './components/Home/Folder';
import LoginForm from './LoginForm';
import ChecklistContainer from './components/ChecklistContainer';
import { NavigationProvider, useNavigation } from './NavigationContext';
import { useGetResponseQuery } from './store/api';
import ReactGA from 'react-ga4';

if (process.env.NODE_ENV === 'production') {
	const TRACKING_ID = process.env.REACT_APP_TRACKING_ID as string;
	ReactGA.initialize(TRACKING_ID);
}

const Routes = () => {
	const {
		navigation,
		openedChecklistId,
		setOpenedChecklistId,
		openedResponse,
		setOpenedResponse,
		setNavigation,
	} = useNavigation();
	const [urlResponseId, setUrlResponseId] = useState<string | null>(null);
	const { data: urlResponse } = useGetResponseQuery(urlResponseId || '', {
		skip: !urlResponseId,
	});

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const responseId = urlParams.get('response');
		if (responseId) {
			setUrlResponseId(responseId);
		}
	}, []);

	useEffect(() => {
		if (urlResponse) {
			setOpenedResponse(urlResponse);
			setOpenedChecklistId(urlResponse.checklistId);
			setNavigation(`checklist-${urlResponse.checklistId}`);
		}
	}, [urlResponse, setOpenedResponse, setOpenedChecklistId, setNavigation]);

	return (
		<>
			{navigation === 'home' && <Home />}
			{navigation.startsWith('folder') && <Folder />}
			{(navigation.startsWith('checklist') ||
				navigation.startsWith('chapter') ||
				navigation.startsWith('question')) && (
				<>
					{openedResponse && openedChecklistId ? (
						<ChecklistContainer
							id={openedChecklistId}
							response={openedResponse}
						/>
					) : (
						openedChecklistId && <ChecklistContainer id={openedChecklistId} />
					)}
				</>
			)}
		</>
	);
};

function App() {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	return (
		<div className="App">
			{!isAuthenticated ? (
				<LoginForm setIsAuthenticated={setIsAuthenticated} />
			) : (
				<NavigationProvider>
					<Routes />
				</NavigationProvider>
			)}
		</div>
	);
}

export default App;

// import { useState, useEffect } from 'react';
// import {
// 	useGetChecklistsQuery,
// 	useGetResponseQuery,
// 	useGetResponsesQuery,
// } from '../../store/api';
// import { Checklist as ChecklistType, Response } from '../../types';
// import socket from '../../store/socket';
// import LoginForm from '../../LoginForm';
// import ChecklistContainer from '../ChecklistContainer';
// import '../../App.scss';
// import PdfGenerator from '../Pdf/PdfGenerator';

// function ResponseContainer({
// 	id,
// 	setOpenedResponse,
// 	setOpenedChecklist,
// }: {
// 	id: string;
// 	setOpenedResponse: React.Dispatch<React.SetStateAction<Response | null>>;
// 	setOpenedChecklist: React.Dispatch<React.SetStateAction<string | null>>;
// }) {
// 	const { data: responses } = useGetResponsesQuery(id);

// 	const handleCreateResponse = () => {
// 		socket.emit('createResponse', {
// 			checklistId: id,
// 		});
// 	};

// 	const handleOpenResponses = () => {
// 		const response = responses?.[0];
// 		if (response) setOpenedResponse(response);
// 		setOpenedChecklist(id);
// 	};

// 	return (
// 		<div style={{ height: '100%' }}>
// 			Responses :
// 			{responses && responses.length > 0 && (
// 				<button onClick={handleOpenResponses}>Ouvrir les réponses</button>
// 			)}
// 			<button onClick={() => handleCreateResponse()}>Create Response</button>
// 			{responses?.[0] && <PdfGenerator response={responses[0]} id={id} />}
// 		</div>
// 	);
// }

// export default function Home() {
// 	const [openedChecklist, setOpenedChecklist] = useState<string | null>(null);
// 	const [openedResponse, setOpenedResponse] = useState<Response | null>(null);
// 	const [isAuthenticated, setIsAuthenticated] = useState(false);
// 	const [urlResponseId, setUrlResponseId] = useState<string | null>(null);
// 	const { data: urlResponse } = useGetResponseQuery(urlResponseId || '', {
// 		skip: !urlResponseId || !isAuthenticated,
// 	});
// 	const { data, error, isLoading } = useGetChecklistsQuery(undefined, {
// 		skip: !isAuthenticated,
// 	});

// 	useEffect(() => {
// 		const urlParams = new URLSearchParams(window.location.search);
// 		const responseId = urlParams.get('response');
// 		if (responseId) {
// 			setUrlResponseId(responseId);
// 		}
// 	}, []);

// 	useEffect(() => {
// 		if (urlResponse && isAuthenticated) {
// 			setOpenedResponse(urlResponse);
// 			setOpenedChecklist(urlResponse.checklistId);
// 		}
// 	}, [urlResponse, isAuthenticated]);

// 	const handleChecklistSelection = (itemId: string) => {
// 		setOpenedChecklist(itemId);
// 	};

// 	const handleDeleteChecklist = (checklistId: string) => {
// 		socket.emit('deleteChecklist', checklistId);
// 	};

// 	const handleCreateChecklist = () => {
// 		socket.emit('createChecklist', {
// 			title: 'Test Checklist',
// 		});
// 	};

// 	if (isLoading) return <div>Loading...</div>;
// 	if (error) {
// 		console.error(error);
// 		return <div>Error</div>;
// 	}

// 	return (
// 		<div>
// 			{' '}
// 			{!isAuthenticated ? (
// 				<LoginForm setIsAuthenticated={setIsAuthenticated} />
// 			) : openedChecklist ? (
// 				<div style={{ height: '100%' }}>
// 					{/* <button onClick={() => setOpenedChecklist(null)}>Back</button> */}
// 					{openedResponse ? (
// 						<ChecklistContainer
// 							id={openedChecklist}
// 							response={openedResponse}
// 							setOpenedResponse={setOpenedResponse}
// 							setOpenedChecklist={setOpenedChecklist}
// 						/>
// 					) : (
// 						<ChecklistContainer
// 							id={openedChecklist}
// 							setOpenedChecklist={setOpenedChecklist}
// 						/>
// 					)}
// 				</div>
// 			) : (
// 				<div className="temporary">
// 					<br />
// 					Créer une réponse crée une copie de la checklist. <br /> Cette copie
// 					est comme un ticket que l'on peut remplir à plusieurs
// 					<br />
// 					Modifier la checklist ne va pas modifier les copies déjà créées
// 					<br />
// 					Mobile only : ctrl shift i - ctrl shift m - samsung galaxy s8+
// 					<br />
// 					<br />
// 					Appuyez sur "create response" puis reload la page pour pouvoir
// 					l'ouvrir
// 					<br />
// 					<br />
// 					<button
// 						onClick={() => {
// 							setIsAuthenticated(false);
// 							socket.emit('logout');
// 							localStorage.removeItem('token');
// 						}}
// 					>
// 						Log out
// 					</button>
// 					<br />
// 					<br />
// 					<br />
// 					<h1>Checklists</h1>
// 					<ul>
// 						{data?.map(
// 							(
// 								item: {
// 									state: ChecklistType;
// 									title: string;
// 									_id: string;
// 								},
// 								index: number
// 							) => (
// 								<li key={index}>
// 									<h2>{item.title}</h2>
// 									<button onClick={() => handleDeleteChecklist(item._id)}>
// 										Delete
// 									</button>
// 									<button onClick={() => handleChecklistSelection(item._id)}>
// 										Open checklist builder
// 									</button>
// 									<br />
// 									<br />
// 									<h3>Réponses</h3>
// 									<ResponseContainer
// 										id={item._id}
// 										setOpenedResponse={setOpenedResponse}
// 										setOpenedChecklist={setOpenedChecklist}
// 									/>
// 									<br />
// 									<br />
// 									<br />
// 									<br />
// 								</li>
// 							)
// 						)}
// 					</ul>
// 					<button onClick={handleCreateChecklist}>Create checklist</button>
// 				</div>
// 			)}
// 		</div>
// 	);
// }
