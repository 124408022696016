import { useGetFolderQuery, useGetFoldersQuery } from '../../store/api';
import { Checklist as ChecklistType, Folder as FolderType } from '../../types';
import { useNavigation } from '../../NavigationContext';
import { ReactComponent as FolderStrokeIcon } from '../icons/folder_stroke.svg';
import CoverImage from 'components/shared/CoverImage/CoverImage';
import styles from './FolderList.module.scss';

function renderCoverImage(
	checklist: ChecklistType,
	width: string = 'calc(100% - 0.5rem)',
	height: string = '100%'
) {
	return (
		<CoverImage
			key={checklist._id}
			id={checklist._id}
			coverImage={checklist.coverImage}
			width={width}
			height={height}
		/>
	);
}

function FolderItem({
	folder,
	handleOpenFolder,
}: {
	folder: FolderType;
	handleOpenFolder: (folderId: string) => void;
}) {
	const { data: checklists } = useGetFolderQuery(folder?._id ?? '', {
		skip: !folder,
	});

	const renderChecklistImages = (checklists: ChecklistType[]) => {
		const imagesToShow = checklists.slice(0, 4);
		const imageCount = imagesToShow.length;

		return (
			<div className={styles.checklistImages}>
				<div className={styles.imageColumn}>
					{imageCount <= 2 ? (
						imagesToShow.map((checklist) => renderCoverImage(checklist))
					) : (
						<>
							{imageCount === 3 &&
								renderCoverImage(
									imagesToShow[0],
									'calc(100% - 0.5rem)',
									'100%'
								)}
							{[...Array(Math.min(2, Math.ceil((imageCount - 1) / 2)))].map(
								(_, index) => (
									<div
										key={index}
										className={styles.imageLine}
										style={{ width: 'calc(100% - 0.5rem)' }}
									>
										{imagesToShow
											.slice(
												index === 0
													? index * 2 + (imageCount === 3 ? 1 : 0)
													: 2,
												index === 0 ? index * 2 + (imageCount === 3 ? 3 : 2) : 4
											)
											.map((checklist) =>
												renderCoverImage(
													checklist,
													'100%',
													'calc(100% - 0.5rem)'
												)
											)}
									</div>
								)
							)}
						</>
					)}
				</div>
			</div>
		);
	};

	return (
		<div
			className={styles.folderItem}
			onClick={() => handleOpenFolder(folder._id)}
		>
			{checklists && checklists?.length > 0 ? (
				renderChecklistImages(checklists)
			) : (
				<div className={styles.noChecklists}>
					<FolderStrokeIcon />
				</div>
			)}

			<div className={styles.folderInfo}>
				<h5>{folder.name}</h5>
				<p>{`${checklists?.length} checklist${
					checklists?.length === 1 ? '' : 's'
				}`}</p>
			</div>
		</div>
	);
}

const FolderList = () => {
	const { setNavigation } = useNavigation();
	const { data: folders, isLoading, error } = useGetFoldersQuery();

	const handleOpenFolder = (folderId: string) => {
		setNavigation(`folder-${folderId}`);
	};

	if (isLoading) return <div>Loading folders...</div>;
	if (error) return <div>Error loading folders</div>;

	return (
		<div className={styles.folderList}>
			{folders?.map((folder: FolderType) => (
				<FolderItem
					key={folder._id}
					folder={folder}
					handleOpenFolder={handleOpenFolder}
				/>
			))}
		</div>
	);
};

export default FolderList;
