import styles from './PdfReport.module.scss';
import { AnswersResponse } from 'types';
import { ReactComponent as ArrowIcon } from '../icons/textArrow.svg';
import ProgressBar from 'components/Checklist/QuestionSelector/ProgressBar';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
// import Avatar from 'components/Avatar/Avatar';

function getType(type: string) {
	switch (type) {
		case 'multipleSelect':
			return 'Sélection multiple';
		case 'singleSelect':
			return 'Sélection unique';
		case 'text':
			return 'Texte';
		case 'number':
			return 'Nombre';
		case 'dateSelect':
			return 'Date';
		case 'file':
			return 'Fichier';
		case 'media':
			return 'Media';
		default:
			return '';
	}
}

export default function PdfReport({
	response,
	answers,
	hasSpacing,
}: {
	response: { state: any; timestamp: string; _id: string };
	answers: AnswersResponse;
	hasSpacing?: boolean;
}) {
	const flattenQuestions = response.state.chapters.reduce(
		(acc: any, chapter: any) => {
			chapter.questions.forEach((question: any) => {
				const answer = answers[question.questionId];
				if (question.type === 'media' && Array.isArray(answer)) {
					// If the answer is an array (multiple images), iterate over it
					(answer as string[]).forEach((mediaAnswer: string) => {
						acc.push({ ...question, answer: mediaAnswer });
					});
				} else {
					acc.push(question);
				}
			});
			return acc;
		},
		[]
	);

	return (
		<div
			className={`${styles.pdfReport} ${hasSpacing ? styles.pageSpacing : ''}`}
		>
			{flattenQuestions.map((question: any, index: number) => {
				const chapter = response.state.chapters.find(
					(chapter: any) => chapter.chapterId === question.chapterId
				);
				const chapterIndex = response.state.chapters.findIndex(
					(chapter: any) => chapter.chapterId === question.chapterId
				);
				const chapterTotal = response.state.chapters.length;
				const answer = question.answer || answers[question.questionId];

				return (
					<div
						key={`${question.questionId}-${index}`}
						className={styles.pdfPage}
					>
						<div className={styles.page}>
							<div className={styles.simpleColumn}>
								<div className={styles.header}>
									<img src={response.state.coverImage} alt="Checklist cover" />
									<div className={styles.column}>
										<div>{response.state.title}</div>
										<div className={styles.spacedFlex}>
											{chapter.title}

											<div className={styles.greyText}>
												Chapitre {chapterIndex + 1} / {chapterTotal}
											</div>
										</div>
									</div>
								</div>

								{chapter.context && (
									<div className={`${styles.greyText} ${styles.textColumn}`}>
										<span>Contexte du chapitre</span>
										<div>{chapter.context}</div>
									</div>
								)}
							</div>

							<div className={styles.content}>
								<div className={styles.blueContainer}>
									<div className={styles.greyText}>
										{getType(question.type)}
									</div>
									<div className={styles.title}>{question.title}</div>
									{(question.type === 'singleSelect' ||
										question.type === 'multipleSelect') && (
										<div className={styles.greyText}>
											Autres choix possibles :{' '}
											<span>
												{question.singleSelect?.options
													?.filter((option: any) => option !== answer)
													.join(', ')}
											</span>
										</div>
									)}
									{answer &&
										(question.type === 'date' ? (
											<div className={styles.answer}>
												<ArrowIcon />
												{format(new Date(answer.toString()), 'd MMMM yyyy', {
													locale: fr,
												})}
											</div>
										) : question.type === 'media' &&
										  question.media.mediaType === 'photo' ? (
											<div className={styles.answer}>
												<img src={answer.toString()} alt="Media" />
											</div>
										) : (
											<div className={styles.answer}>
												<ArrowIcon />
												{answer.toString()}
											</div>
										))}
									<ProgressBar
										currentQuestionId={question.questionId}
										chapter={chapter}
										answers={answers}
									/>
								</div>
								{question.context && (
									<div className={`${styles.greyText} ${styles.textColumn}`}>
										<span>Contexte de la question</span>
										<div>{question.context}</div>

										<div className={styles.contextMedia}>
											{/* {question?.contextMedia?.map(
												(media: any, index: number) => (
													<img
														key={index}
														className={styles.contextImg}
														src={media}
														alt="Media"
													/>
												)
											)} */}
											{question.imageMedia && (
												<img
													className={styles.contextImg}
													src={question.imageMedia}
													alt="Media"
												/>
											)}
										</div>
									</div>
								)}
							</div>
							<div className={styles.footer}>
								<div>
									{index + 1} / {flattenQuestions.length}
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}
