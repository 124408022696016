import { useMemo, useEffect, useRef, useCallback } from 'react';
import { ReactComponent as CommentIcon } from '../icons/comment.svg';
import { ReactComponent as HelpIcon } from '../icons/exclamation_mark.svg';
import { ReactComponent as ArrowUpIcon } from '../icons/arrow_up.svg';
import { ReactComponent as ArrowDownIcon } from '../icons/arrow_down.svg';
import {
	Checklist as ChecklistType,
	Chapter as ChapterType,
	Question as QuestionType,
	AnswersResponse,
} from '../../types';
import Checklist from '../Checklist/Checklist';
import { getNextQuestionId } from '../Checklist/checklistUtils';
import { useNavigation } from '../../NavigationContext';
import styles from './ScrollableMobileResponse.module.scss';

export default function ScrollableMobileResponse({
	id,
	data,
	values,
	responseId,
	isAutoNavigationDisabled,
	setIsAutoNavigationDisabled,
	setCommentsOn,
}: {
	id: string;
	data: ChecklistType;
	values: AnswersResponse;
	responseId: string;
	isAutoNavigationDisabled: boolean;
	setIsAutoNavigationDisabled: React.Dispatch<React.SetStateAction<boolean>>;
	setCommentsOn: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { navigation, setNavigation } = useNavigation();
	const containerRef = useRef<HTMLDivElement>(null);
	const questionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
	const chapter = useMemo(
		() =>
			navigation.includes('question')
				? (data.chapters.find(
						(chapter: ChapterType) =>
							chapter.chapterId === navigation.split('-')[1]
				  ) as ChapterType | undefined)
				: null,

		[navigation, data]
	);

	const question = useMemo(() => {
		if (navigation.includes('question') && chapter) {
			return chapter.questions.find(
				(question: QuestionType) =>
					question.questionId === navigation.split('-')[2]
			);
		}
		return null;
	}, [navigation, chapter]);

	const updateNavigationOnScroll = useCallback(
		(entries: IntersectionObserverEntry[]) => {
			console.log('isAutoNavigationDisabled', isAutoNavigationDisabled);
			if (!isAutoNavigationDisabled) {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						const [, chapterId, questionId] = entry.target.id.split('-');
						setNavigation(`question-${chapterId}-${questionId}`);
					}
				});
			}
		},
		[setNavigation, isAutoNavigationDisabled]
	);

	useEffect(() => {
		let scrollTimeout: NodeJS.Timeout;

		const observer = new IntersectionObserver(updateNavigationOnScroll, {
			root: containerRef.current,
			threshold: 0.5,
		});

		const observeQuestions = () => {
			observer.disconnect();
			Object.values(questionRefs.current).forEach((ref) => {
				if (ref) observer.observe(ref);
			});
		};

		const handleUserScroll = () => {
			if (containerRef.current) {
				clearTimeout(scrollTimeout);
				setIsAutoNavigationDisabled(false);
				setTimeout(() => {
					observeQuestions();
					setIsAutoNavigationDisabled(true);
				}, 1000);
			}
		};

		observeQuestions();

		const container = containerRef.current;
		if (container) {
			container.addEventListener('wheel', handleUserScroll);
			container.addEventListener('touchmove', handleUserScroll);
		}

		return () => {
			observer.disconnect();
			if (container) {
				container.removeEventListener('wheel', handleUserScroll);
				container.removeEventListener('touchmove', handleUserScroll);
			}
			clearTimeout(scrollTimeout);
		};
	}, [updateNavigationOnScroll, chapter, setIsAutoNavigationDisabled]);

	useEffect(() => {
		if (navigation.includes('question')) {
			const questionId = navigation.split('-')[2];
			const questionElement = questionRefs.current[questionId];
			if (questionElement && containerRef.current) {
				questionElement.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				});
			}
		}
	}, [navigation]);

	return (
		<div className={styles.gradientContainer}>
			<div className={styles.scrollContainer} ref={containerRef}>
				{chapter?.questions &&
					chapter.questions.map((question: QuestionType) => (
						<div
							key={question.questionId}
							id={`question-${chapter.chapterId}-${question.questionId}`}
							ref={(el) => (questionRefs.current[question.questionId] = el)}
							className={styles.checklist}
						>
							<Checklist
								id={id}
								data={data}
								values={values}
								responseId={responseId}
								mode="response"
								navigation={`question-${chapter.chapterId}-${question.questionId}`}
								setNavigation={setNavigation}
							/>
						</div>
					))}
			</div>

			{question && (
				<div className={styles.navButtons}>
					<div className={styles.group}>
						<button onClick={() => setCommentsOn(true)}>
							<CommentIcon />
						</button>
						<button>
							<HelpIcon />
						</button>
					</div>
					<div className={styles.group}>
						<button
							onClick={() => {
								const previousQuestion = getNextQuestionId(
									data.chapters,
									question,
									false
									// values
								);
								if (previousQuestion) {
									const previousChapter = data.chapters.find((c: ChapterType) =>
										c.questions.some(
											(q: QuestionType) => q.questionId === previousQuestion
										)
									) as ChapterType | undefined;
									if (previousChapter) {
										setNavigation(
											`question-${previousChapter.chapterId}-${previousQuestion}`
										);
										setIsAutoNavigationDisabled(true);
									}
								}
							}}
						>
							<ArrowUpIcon />
						</button>
						<button
							onClick={() => {
								const nextQuestion = getNextQuestionId(
									data.chapters,
									question,
									true
								);
								if (nextQuestion) {
									const nextChapter = data.chapters.find((c: ChapterType) =>
										c.questions.some(
											(q: QuestionType) => q.questionId === nextQuestion
										)
									) as ChapterType | undefined;
									if (nextChapter) {
										setNavigation(
											`question-${nextChapter.chapterId}-${nextQuestion}`
										);
										setIsAutoNavigationDisabled(true);
									}
								}
							}}
						>
							<ArrowDownIcon />
						</button>
					</div>
				</div>
			)}
		</div>
	);
}
