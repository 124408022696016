const prodApi = 'https://soop.cl/api';
const localhostApi = 'http://localhost:3000/api';

const prodSocket = 'https://soop.cl';
const localhostSocket = 'ws://localhost:3000';

const baseUrl =
	window.location.hostname === 'localhost' ? localhostApi : prodApi;

const socketUrl =
	window.location.hostname === 'localhost' ? localhostSocket : prodSocket;

const config = {
	baseUrl,
	socketUrl,
};

export default config;
