import { useEffect } from 'react';
import { useGetFoldersQuery } from '../../store/api';
import socket from '../../store/socket';
import { ReactComponent as AddIcon } from '../icons/add.svg';
import { ReactComponent as FolderIcon } from '../icons/folder.svg';
import FolderList from './FolderList';
import Navbar from 'components/Navbar/Navbar';
import styles from './Home.module.scss';

export default function Home() {
	const { data: folders } = useGetFoldersQuery();

	useEffect(() => {
		document.title = 'Dashboard | Dossiers';
	}, []);

	const handleNewFolder = () => {
		socket.emit('createFolder', {
			name: 'Nouveau dossier',
		});
	};

	return (
		<div className={styles.home}>
			<Navbar />
			<div className={styles.homeContent}>
				<div className={styles.flex}>
					<div className={styles.homeItemContainer}>
						<FolderIcon />
					</div>
					<div className={styles.homeItemText}>
						{`${folders?.length} Dossier${
							folders?.length && folders?.length === 1 ? '' : 's'
						}`}
						<h2>Dossiers</h2>
					</div>
					<div className={styles.homeItemButtonContainer}>
						<button onClick={handleNewFolder} className={styles.greyButton}>
							Nouveau dossier
						</button>
						<button onClick={() => {}} className={styles.iconButton}>
							<AddIcon fill="#FFFFFF" />
							Créer
						</button>
					</div>
				</div>

				<div className={styles.content}>
					<div className={styles.navigationContainer}>
						<div className={styles.navigation}>
							<div className={`${styles.navigationItem} ${styles.active}`}>
								Toutes les Checklists
							</div>
						</div>
					</div>
					<FolderList />
				</div>
			</div>
		</div>
	);
}
