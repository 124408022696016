import { useState } from 'react';
import { useGetAnswerQuery } from '../../store/api';
// import { renderToString } from 'react-dom/server';
import { Checklist as ChecklistType } from '../../types';
import { ReactComponent as EyeIcon } from '../icons/eye.svg';
import PdfReport from './PdfReport';
// import config from '../../config';
import styles from './PdfReport.module.scss';

// const baseUrl = config.baseUrl;

export default function PdfGenerator({
	response,
	id,
}: {
	response: {
		state: ChecklistType;
		timestamp: string;
		_id: string;
	};
	id: string;
}) {
	const [visualizePdf, setVisualizePdf] = useState(false);
	// const [loading, setLoading] = useState(false);
	const { data } = useGetAnswerQuery(response._id);

	console.log(response, id);

	// const handleDownload = async () => {
	// 	try {
	// 		setLoading(true);

	// 		if (!data) {
	// 			throw new Error('No data to generate PDF');
	// 		} else {
	// 			const htmlContent = renderToString(
	// 				<PdfReport response={response} answers={data} />
	// 			);
	// 			const pdfResponse = await fetch(`${baseUrl}/pdf/generate`, {
	// 				method: 'POST',
	// 				headers: {
	// 					'Content-Type': 'application/json',
	// 					Authorization: `Bearer ${localStorage.getItem('token')}`,
	// 				},
	// 				body: JSON.stringify({ html: htmlContent }),
	// 			});

	// 			if (!pdfResponse.ok) {
	// 				throw new Error('Erreur lors de la génération du PDF');
	// 			}

	// 			const pdfBlob = await pdfResponse.blob();
	// 			const url = window.URL.createObjectURL(pdfBlob);
	// 			const link = document.createElement('a');
	// 			link.href = url;
	// 			link.setAttribute('download', 'rapport.pdf');
	// 			document.body.appendChild(link);
	// 			link.click();
	// 			link.parentNode?.removeChild(link);
	// 		}
	// 	} catch (error) {
	// 		console.error('Erreur lors du téléchargement du PDF :', error);
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };

	return (
		<div>
			<div className={styles.buttonsFlex}>
				{/* <button onClick={handleDownload} disabled={loading}>
					{loading ? 'Génération...' : 'Download PDF'}
				</button> */}
				<button
					className={styles.primaryButton}
					onClick={() => setVisualizePdf(true)}
					// disabled={loading}
				>
					<EyeIcon />
					Visualiser le PDF
				</button>
			</div>

			{/* {loading && <div className={styles.loader}>Loading...</div>} */}

			{visualizePdf && (
				<div
					onClick={() => setVisualizePdf(false)}
					className={styles.darkBackground}
				>
					<div className={styles.pdfContainer}>
						{' '}
						{data && (
							<PdfReport response={response} answers={data} hasSpacing />
						)}
					</div>
				</div>
			)}
		</div>
	);
}
