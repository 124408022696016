import { useState } from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Question as QuestionType } from '../../../types';
import Calendar from '../../Calendar/Calendar';
import styles from './DateSelect.module.scss';
import { useDeviceType } from 'useDeviceType';

export default function DateSelect({
	question,
	value,
	onChange,
	goToNextQuestion,
}: {
	question: QuestionType;
	value: string;
	onChange: (value: string) => void;
	goToNextQuestion: () => void;
}) {
	const { isTypeOrLarger } = useDeviceType();
	const [openedCalendar, setOpenedCalendar] = useState(false);

	const formatDate = (dateString: string) => {
		const date = new Date(dateString);
		return format(date, 'dd MMMM yyyy', { locale: fr });
	};

	return (
		<div>
			{openedCalendar && (
				<div className={styles.calendarContainer}>
					<Calendar
						mode="simple responsive"
						simpleTitle="Choisir une date"
						close={() => {
							setOpenedCalendar(false);
						}}
						selectDay={(date: number | null) => {
							date && onChange(new Date(date).toISOString());
							date && !isTypeOrLarger('md') && goToNextQuestion();
						}}
					/>
				</div>
			)}
			<div>
				<button
					className={styles.secondaryButton}
					onClick={() => setOpenedCalendar(true)}
				>
					{value === '' ? 'Choisir une date' : formatDate(value)}
				</button>
			</div>
		</div>
	);
}
