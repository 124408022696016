import { useRef, useEffect, useState, useMemo } from 'react';
import { useNavigation } from '../../NavigationContext';
import socket from '../../store/socket';
import { Chapter as ChapterType, Question as QuestionType } from '../../types';
import { ReactComponent as PlusIcon } from '../icons/plus.svg';
import { ReactComponent as PlayIcon } from '../icons/play_circle_filled.svg';
import { ReactComponent as MoreIcon } from '../icons/more.svg';
import { ReactComponent as DuplicateIcon } from '../icons/duplicate.svg';
import { Response } from '../../types';
import DraggableList from '../DraggableList';
import styles from './DraggableNavigation.module.scss';
import Avatar from '../Avatar/Avatar';

const DeletePopup = ({
	checklistId,
	question,
}: {
	checklistId: string;
	question: QuestionType;
}) => {
	const deletePopupRef = useRef<HTMLDivElement>(null);
	const [openDeletePopup, setOpenDeletePopup] = useState(false);
	const { navigation } = useNavigation();

	useEffect(() => {
		const handleClick = (e: MouseEvent) => {
			if (
				deletePopupRef.current &&
				!deletePopupRef.current.contains(e.target as Node)
			) {
				setOpenDeletePopup(false);
			}
		};
		document.addEventListener('mousedown', handleClick);
		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	const handleDeleteQuestion = (question: QuestionType) => {
		socket.emit('deleteQuestion', {
			checklistId,
			chapterId: question.chapterId,
			questionId: question.questionId,
		});
		setOpenDeletePopup(false);
	};

	const handleDuplicateQuestion = (question: QuestionType) => {
		socket.emit('duplicateQuestion', {
			checklistId,
			chapterId: question.chapterId,
			questionId: question.questionId,
		});
	};

	return (
		<>
			{navigation ===
				`question-${question.chapterId}-${question.questionId}` && (
				<MoreIcon onClick={() => setOpenDeletePopup(true)} />
			)}
			{openDeletePopup && (
				<div ref={deletePopupRef} className={styles.deletePopup}>
					<button
						onClick={() => handleDuplicateQuestion(question)}
						className={styles.duplicate}
					>
						<DuplicateIcon />
						Dupliquer cette question
					</button>
					<button
						onClick={() => handleDeleteQuestion(question)}
						className={styles.delete}
					>
						Supprimer cette question
					</button>
				</div>
			)}
		</>
	);
};

export default function DraggableNavigation({
	firstResponse,
	checklistId,
	title,
	chapters,
}: {
	firstResponse?: Response;
	checklistId: string;
	title: string;
	chapters: ChapterType[];
}) {
	const { navigation, setNavigation, setOpenedResponse } = useNavigation();
	const [updatesCount, setUpdatesCount] = useState(0);
	const [pendingCreation, setPendingCreation] = useState<
		'chapter' | 'question' | null
	>(null);

	const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
	const [heights, setHeights] = useState<number[]>([]);

	const items = useMemo(() => {
		const flatItems: Array<{
			type: 'chapter' | 'question';
			data: ChapterType | QuestionType;
		}> = [];

		chapters?.forEach((chapter) => {
			flatItems.push({ type: 'chapter', data: chapter });
			chapter.questions?.forEach((question) => {
				if (
					navigation !== 'checklist' &&
					navigation.includes(chapter.chapterId)
				) {
					flatItems.push({ type: 'question', data: question });
				}
			});
		});

		return flatItems;
	}, [chapters, navigation]);

	const order = useMemo(() => {
		const chapterMap = new Map<string, number>();
		let currentChapterOrder = 0;

		const sortedChapters = [...chapters].sort((a, b) => a.index - b.index);

		sortedChapters.forEach((chapter, i) => {
			chapterMap.set(chapter.chapterId, currentChapterOrder);
			currentChapterOrder++;
		});

		return items
			.map((item, i) => {
				if (item.type === 'chapter') {
					return {
						originalIndex: i,
						order: chapterMap.get((item.data as ChapterType).chapterId) ?? 0,
					};
				} else {
					const question = item.data as QuestionType;
					const chapterOrder =
						chapterMap.get(question.chapterId.toString()) ?? 0;
					return {
						originalIndex: i,
						order: chapterOrder + question.index / 100,
					};
				}
			})
			.sort((a, b) => a.order - b.order)
			.map((item) => item.originalIndex);
	}, [chapters, items]);

	useEffect(() => {
		itemRefs.current = itemRefs.current.slice(0, items.length);
		while (itemRefs.current.length < items.length) {
			itemRefs.current.push(null);
		}
	}, [items]);

	useEffect(() => {
		setTimeout(() => {
			setHeights(
				items.map((_, index) => {
					return itemRefs.current[index]?.offsetHeight || 0;
				})
			);
		}, 50);
	}, [items, updatesCount, navigation]);

	function findMovedElement(arr1: number[], arr2: number[]) {
		for (let i = 0; i < arr1.length; i++) {
			if (arr1[i] !== arr2[i]) {
				if (arr1[i + 1] === arr2[i]) {
					return arr1[i];
				} else if (arr1[i] === arr2[i + 1]) {
					return arr2[i];
				}
			}
		}
	}

	function arraysEqual(arr1: number[], arr2: number[]) {
		if (arr1.length !== arr2.length) return false;
		for (let i = 0; i < arr1.length; i++) {
			if (arr1[i] !== arr2[i]) return false;
		}
		return true;
	}

	const handleReorderItems = (newOrder: number[]) => {
		if (arraysEqual(order, newOrder)) return;
		if (navigation.startsWith('checklist')) {
			const chapterOrders = newOrder.map(
				(index) =>
					(
						chapters.find(
							(chapter: ChapterType) =>
								chapter.index ===
								chapters.map((chapter: ChapterType) => chapter.index)[index]
						) as any
					)?.chapterId
			);
			socket.emit('reorderChapters', {
				checklistId,
				chapterOrders,
			});
		} else {
			const orderedItems = order.map((orderIndex) => items[orderIndex]);
			const reorderedItems = newOrder.map((orderIndex) => items[orderIndex]);
			let movedIndex = findMovedElement(order, newOrder) || 0;
			if (items[movedIndex].type === 'chapter') {
				movedIndex++;
			}

			if (newOrder.indexOf(movedIndex) === 0) {
				setHeights(
					items.map((_, index) => {
						return itemRefs.current[index]?.offsetHeight || 0;
					})
				);
				return;
			}

			let currentChapter = null;
			let targetChapter = null;

			for (let i = order.indexOf(movedIndex); i > -1; i--) {
				if (orderedItems[i].type === 'chapter') {
					currentChapter = orderedItems[i].data;
					break;
				}
			}

			for (let i = newOrder.indexOf(movedIndex); i > -1; i--) {
				if (reorderedItems[i].type === 'chapter') {
					targetChapter = reorderedItems[i].data;
					break;
				}
			}

			const questionOrders = [];
			let isInOriginalChapter = false;
			for (let i = 0; i < reorderedItems.length; i++) {
				const currentItem = reorderedItems[i];
				if (
					currentItem.type === 'chapter' &&
					currentItem.data?.chapterId === currentChapter?.chapterId
				) {
					isInOriginalChapter = true;
				} else if (currentItem.type === 'chapter') {
					isInOriginalChapter = false;
				}

				if (isInOriginalChapter && currentItem.type === 'question') {
					questionOrders.push((currentItem.data as QuestionType).questionId);
				}
			}

			const targetQuestionOrders: string[] = [];
			let isInTargetChapter = false;
			for (let i = 0; i < reorderedItems.length; i++) {
				const currentItem = reorderedItems[i];
				if (
					currentItem.type === 'chapter' &&
					currentItem.data?.chapterId === targetChapter?.chapterId
				) {
					isInTargetChapter = true;
				} else if (currentItem.type === 'chapter') {
					isInTargetChapter = false;
				}

				if (isInTargetChapter && currentItem.type === 'question') {
					targetQuestionOrders.push(
						(currentItem.data as QuestionType).questionId
					);
				}
			}

			const targetChapterIncludesQuestion = (
				targetChapter as ChapterType
			)?.questions?.find(
				(question) => question.questionId === targetQuestionOrders[0]
			);
			if (targetQuestionOrders.length === 1 && !targetChapterIncludesQuestion) {
				(targetChapter as ChapterType)?.questions.forEach((question) => {
					targetQuestionOrders.push(question.questionId);
				});
				setNavigation(
					`question-${targetChapter?.chapterId}-${targetQuestionOrders[0]}`
				);
			}

			const reorderPayload = {
				checklistId,
				chapterId: currentChapter?.chapterId,
				targetChapterId: targetChapter?.chapterId,
				questionOrders,
				targetQuestionOrders,
			};

			if (reorderPayload.chapterId === reorderPayload.targetChapterId) {
				reorderPayload.targetChapterId = '';
				reorderPayload.targetQuestionOrders = [];
			}

			if (reorderPayload.questionOrders.length > 0) {
				socket.emit('reorderQuestions', reorderPayload);
			}
		}
	};

	const handleCreateQuestion = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		e.stopPropagation();
		if (
			navigation.startsWith('chapter-') ||
			navigation.startsWith('question-')
		) {
			setPendingCreation('question');
			setUpdatesCount(updatesCount + 1);
			socket.emit('createQuestion', {
				checklistId,
				chapterId: navigation.split('-')[1],
				title: '',
				type: null,
				useChapterContext: true,
			});
		}
	};

	const handleCreateChapter = () => {
		setPendingCreation('chapter');
		socket.emit('createChapter', {
			checklistId,
			title: ``,
			context: '',
		});
	};

	useEffect(() => {
		const handleCreateResponse = (response: {
			questionId: string;
			chapterId: string;
		}) => {
			if (pendingCreation === 'chapter' && !response.questionId) {
				setNavigation(`chapter-${response.chapterId}`);
				setPendingCreation(null);
			} else if (pendingCreation === 'question' && response.questionId) {
				setNavigation(`question-${response.chapterId}-${response.questionId}`);
				setPendingCreation(null);
			}
		};

		socket.on('chapterCreated', handleCreateResponse);
		socket.on('questionCreated', handleCreateResponse);

		return () => {
			socket.off('chapterCreated', handleCreateResponse);
			socket.off('questionCreated', handleCreateResponse);
		};
	}, [pendingCreation, setNavigation]);

	return (
		<div className={styles.draggableNavigation}>
			<div className={styles.navigationContainer}>
				<div className={styles.navButtons}>
					<button
						onClick={() => {
							firstResponse && setOpenedResponse(firstResponse);
						}}
					>
						Réponses
					</button>
					<button className={styles.selected}>Éditer la Checklist</button>
				</div>
				<div
					onClick={() => setNavigation('checklist')}
					className={styles.navigationHeader}
				>
					<h5>{title}</h5>
					<div className={styles.lastEdit}>
						<Avatar small name={'Aa Bb'} />
						modifié récemment
					</div>
				</div>
			</div>
			<div className={styles.navigationContent}>
				<DraggableList
					items={items.map((item, i) => {
						if (item.type === 'chapter') {
							const chapter = item.data as ChapterType;
							return (
								<div
									ref={(el) => {
										itemRefs.current[i] = el;
									}}
									key={chapter.chapterId}
									className={`${styles.element} `}
									onClick={() => {
										if (navigation.includes(chapter.chapterId)) {
											setNavigation(`checklist`);
										} else {
											setNavigation(`chapter-${chapter.chapterId}`);
											setTimeout(() => {
												itemRefs.current[i]?.scrollIntoView({
													behavior: 'smooth',
													block: 'start',
												});
											}, 100);
										}
									}}
								>
									<div
										className={`${styles.chapter} ${
											navigation.includes(chapter.chapterId)
												? styles.selected
												: ''
										}`}
									>
										<div className={styles.title}>
											<div className={styles.subtitle}>
												Chapitre{' '}
												{(
													chapters.find(
														(c) => c.chapterId === chapter.chapterId
													) || {
														index: 0,
													}
												).index + 1}
											</div>
											{chapter.title === ''
												? 'Nouveau chapitre'
												: chapter.title}
										</div>
										<div
											onClick={handleCreateQuestion}
											className={styles.arrowIcon}
										>
											{navigation.includes(chapter.chapterId) ? (
												<PlusIcon />
											) : null}
										</div>
									</div>
								</div>
							);
						} else {
							const question = item.data as QuestionType;
							return (
								<div
									ref={(el) => {
										itemRefs.current[i] = el;
									}}
									key={question.questionId}
									className={`${styles.element}`}
									onClick={() =>
										setNavigation(
											`question-${question.chapterId}-${question.questionId}`
										)
									}
								>
									<div className={styles.question}>
										<div
											className={`${styles.questionContent} ${
												navigation.includes(question.questionId)
													? styles.selected
													: ''
											}`}
										>
											{navigation.includes(question.questionId) && <PlayIcon />}
											<div className={styles.spacedFlex}>
												<div className={styles.title}>
													{question.title === ''
														? 'Nouvelle question'
														: question.title}
													<div className={styles.subtitle}>
														{question.type === 'text'
															? 'Texte'
															: question.type === 'multipleSelect'
															? 'Choix multiple'
															: question.type === 'singleSelect'
															? 'Choix unique'
															: question.type === 'number'
															? 'Nombre'
															: question.type === 'date'
															? 'Date'
															: question.type === 'media'
															? 'Média'
															: 'Non défini'}
													</div>
												</div>
												{navigation ===
													`question-${question.chapterId}-${question.questionId}` && (
													<DeletePopup
														checklistId={checklistId}
														question={question}
													/>
												)}
											</div>
										</div>
									</div>
								</div>
							);
						}
					})}
					itemsData={items}
					undraggableItems={
						navigation.startsWith('checklist')
							? []
							: [...chapters.map((chapter) => `${chapter.chapterId}`)]
					}
					burgerIcon={[...chapters.map((chapter) => `${chapter.chapterId}`)]}
					noIcon={[
						...chapters
							?.map((chapter) =>
								chapter.questions?.map((question) => question.questionId)
							)
							.flat(),
					]}
					order={order}
					setOrder={(newOrder) => handleReorderItems(newOrder)}
					heights={heights}
					disableInitialAnimation
					translateY={1.65}
				/>
			</div>
			<div className={styles.navigationFooter}>
				<button className={styles.noBorderButton} onClick={handleCreateChapter}>
					<PlusIcon />
					Nouveau chapitre
				</button>
			</div>
		</div>
	);
}
