import { useState, useEffect, useRef, useCallback } from 'react';
import socket from '../../../store/socket';
import styles from './InvitePopup.module.scss';
import { ReactComponent as RegenerateIcon } from '../../icons/regenerate.svg';
import { ReactComponent as CopyIcon } from '../../icons/duplicate.svg';
import { useNavigation } from 'NavigationContext';
import config from '../../../config';

export default function InvitePopup({ close }: { close: () => void }) {
	const { openedChecklistId } = useNavigation();
	const [copied, setCopied] = useState(false);
	const [inviteLink, setInviteLink] = useState('');
	const popupRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (
				popupRef.current &&
				!popupRef.current.contains(event.target as Node)
			) {
				close();
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [close]);

	useEffect(() => {
		socket.on('responseCreated', (data: { _id: string }) => {
			const newInviteLink = `${config.baseUrl
				.replace('/api', '')
				.replace('localhost:3000', 'localhost:3001')}/?response=${data._id}`;
			setInviteLink(newInviteLink);
		});
	}, []);

	const handleCreateResponse = useCallback(() => {
		socket.emit('createResponse', { checklistId: openedChecklistId });
	}, [openedChecklistId]);

	useEffect(() => {
		handleCreateResponse();
	}, [handleCreateResponse]);

	const handleCopy = () => {
		navigator.clipboard.writeText(inviteLink);
		setCopied(true);
		setTimeout(() => setCopied(false), 2000);
	};

	return (
		<div className={styles.popupContainer}>
			<div ref={popupRef} className={styles.invitePopup}>
				<h4>Inviter à remplir cette Checklist</h4>
				<div className={styles.subtitle}>Lien</div>
				<div className={styles.linkContainer}>
					<input
						type="text"
						readOnly
						value={inviteLink}
						className={styles.linkInput}
					/>
					<button onClick={handleCopy} className={styles.primaryButton}>
						<CopyIcon />
						{copied ? 'Copié !' : 'Copier'}
					</button>
				</div>
				<p>
					Il s’agit d’un lien unique :{' '}
					<span>si vous le partagez à plusieurs personnes</span>, elles pourront
					remplir la Checklist en même temps pour former{' '}
					<span>une seule et même réponse</span>.
				</p>
				<div className={styles.separator} />
				<p>
					Si vous souhaitez obtenir des <span>réponses différentes</span>,
					cliquez sur le bouton pour <span>Générer un nouveau lien</span>,
					copiez le lien, et ne l’envoyez qu’à <span>une seule personne</span>.
				</p>
				<button className={styles.greyButton} onClick={handleCreateResponse}>
					<RegenerateIcon />
					Générer un nouveau lien
				</button>
			</div>
		</div>
	);
}
