import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
	Checklist,
	Response,
	AnswersResponse,
	User,
	Comment,
	Folder,
} from '../types';
import config from '../config';

const getToken = () => localStorage.getItem('token');

interface UploadResponse {
	message: string;
	files: { filename: string; url: string }[];
}

const baseUrl = config.baseUrl;

export const api = createApi({
	reducerPath: 'checklistsApi',
	baseQuery: fetchBaseQuery({
		baseUrl,
		prepareHeaders: (headers) => {
			const token = getToken();
			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: (builder) => ({
		getChecklists: builder.query<
			{ state: Checklist; _id: string; title: string }[],
			void
		>({
			query: () => ({
				url: '/checklists/',
				method: 'GET',
			}),
		}),
		getChecklist: builder.query<
			{ state: Checklist; _id: string; title: string; folderId: string },
			string
		>({
			query: (id) => ({
				url: `/checklists/${id}`,
				method: 'GET',
			}),
		}),
		getResponse: builder.query<Response, string>({
			query: (id) => ({
				url: `/responses/link/${id}`,
				method: 'GET',
			}),
		}),
		getResponses: builder.query<Response[], string>({
			query: (id) => ({
				url: `/responses/${id}`,
				method: 'GET',
			}),
		}),
		getAnswer: builder.query<AnswersResponse, string>({
			query: (id) => ({
				url: `/responses/answer/${id}`,
				method: 'GET',
			}),
		}),
		getAnswers: builder.query<
			(Response & { state: AnswersResponse })[],
			string
		>({
			query: (checklistId) => ({
				url: `/responses/answers/${checklistId}`,
				method: 'GET',
			}),
		}),
		uploadFiles: builder.mutation<UploadResponse, FormData>({
			query: (formData) => ({
				url: 'files',
				method: 'POST',
				body: formData,
			}),
		}),
		deleteFile: builder.mutation<{ message: string }, { url: string }>({
			query: ({ url }) => ({
				url: 'files',
				method: 'DELETE',
				body: { url },
			}),
		}),
		getEditingChecklist: builder.query<User[], string>({
			query: (id) => ({
				url: `/editing/checklist/${id}`,
				method: 'GET',
			}),
		}),
		getEditingResponse: builder.query<User[], string>({
			query: (id) => ({
				url: `/editing/response/${id}`,
				method: 'GET',
			}),
		}),
		getComments: builder.query<
			Comment[],
			{ entityType: string; entityId: string }
		>({
			query: ({ entityType, entityId }) => ({
				url: `/comment/${entityType}/${entityId}`,
				method: 'GET',
			}),
		}),
		getMe: builder.query<User, void>({
			query: () => ({
				url: '/user/me',
				method: 'GET',
			}),
		}),
		getFolders: builder.query<Folder[], void>({
			query: () => '/checklists/folders',
		}),
		getFolder: builder.query<Checklist[], string>({
			query: (id) => `/checklists/folders/${id}`,
		}),
	}),
});

export const {
	useGetChecklistsQuery,
	useGetChecklistQuery,
	useGetResponsesQuery,
	useGetResponseQuery,
	useGetAnswerQuery,
	useGetAnswersQuery,
	useUploadFilesMutation,
	useDeleteFileMutation,
	useGetEditingChecklistQuery,
	useGetEditingResponseQuery,
	useGetCommentsQuery,
	useGetMeQuery,
	useGetFoldersQuery,
	useGetFolderQuery,
} = api;
