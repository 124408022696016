import React, { createContext, useState, useContext, ReactNode } from 'react';
import { Response } from './types';

interface NavigationContextType {
	navigation: string;
	setNavigation: (nav: string) => void;
	openedChecklistId: string | null;
	setOpenedChecklistId: (checklistId: string | null) => void;
	openedResponse: Response | null;
	setOpenedResponse: (response: Response | null) => void;
}

const NavigationContext = createContext<NavigationContextType | undefined>(
	undefined
);

export const NavigationProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [navigation, setNavigation] = useState<string>('home');
	const [openedChecklistId, setOpenedChecklistId] = useState<string | null>(
		null
	);
	const [openedResponse, setOpenedResponse] = useState<Response | null>(null);

	return (
		<NavigationContext.Provider
			value={{
				navigation,
				setNavigation,
				openedChecklistId,
				setOpenedChecklistId,
				openedResponse,
				setOpenedResponse,
			}}
		>
			{children}
		</NavigationContext.Provider>
	);
};

export const useNavigation = () => {
	const context = useContext(NavigationContext);
	if (context === undefined) {
		throw new Error('useNavigation must be used within a NavigationProvider');
	}
	return context;
};
