import styles from './ResponsesMenu.module.scss';
import { Response, Chapter as ChapterType, AnswersResponse } from '../../types';
import { ReactComponent as PlayIcon } from '../icons/play_circle_filled.svg';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import ProgressBar from 'components/Checklist/QuestionSelector/ProgressBar';
import { useNavigation } from '../../NavigationContext';
import { useGetAnswersQuery } from '../../store/api';
import { useEffect } from 'react';
import Avatar from 'components/Avatar/Avatar';

export default function ResponsesMenu({
	currentAnswers,
	checklistId,
	responses,
	chapters,
}: {
	currentAnswers: AnswersResponse;
	checklistId: string;
	responses: Response[];
	chapters: ChapterType[];
}) {
	const { data: answers, refetch } = useGetAnswersQuery(checklistId);
	const { openedResponse, setOpenedResponse } = useNavigation();

	useEffect(() => {
		if (currentAnswers) {
			refetch();
		}
	}, [currentAnswers, refetch]);

	return (
		<div className={styles.menu}>
			<div className={styles.navButtons}>
				<button className={styles.selected}>Réponses</button>
				<button onClick={() => setOpenedResponse(null)}>
					Éditer la Checklist
				</button>
			</div>
			{responses.map((response, index) => (
				<button
					onClick={() => setOpenedResponse(response)}
					key={index}
					className={`${styles.menuItem} ${
						openedResponse?._id === response._id ? styles.selected : ''
					}`}
				>
					<div className={styles.modifiedBy}>
						{response.modifiedBy?.slice(0, 2).map((user) => (
							<Avatar
								key={user.userId}
								name={`${user.firstName} ${user.lastName}`}
								id={user.userId}
								small
								column
							/>
						))}
						{response.modifiedBy && response.modifiedBy.length > 2 && (
							<Avatar
								name={`+ ${response.modifiedBy.length - 2}`}
								color="grey"
								small
								column
							/>
						)}
					</div>
					<div className={styles.column}>
						<div className={styles.tabBarItems}>
							{format(new Date(response.timestamp), 'd MMM yyyy, HH:mm', {
								locale: fr,
							})}
						</div>
						<div className={styles.title}>Réponse #{response.index}</div>
						<div className={styles.remaining}>
							{answers && (
								<ProgressBar
									chapters={chapters}
									answers={answers[index]?.state}
									noPadding
									width="10rem"
								/>
							)}
						</div>
					</div>
					{openedResponse?._id === response._id && (
						<div className={styles.playIcon}>
							<PlayIcon />
						</div>
					)}
				</button>
			))}
		</div>
	);
}
