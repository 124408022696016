import {useState, useEffect, useRef, useLayoutEffect} from 'react';
import styles from './DateSelector.module.scss';
import {useDispatch} from 'react-redux';
import {
	// setAndFormatStartDate,
	// setAndFormatEndDate,
	setStartModify,
	setEndModify,
} from '../calendarSlice';
import {format} from 'date-fns';

export const DateSelector = ({
	isStart,
	date,
	otherDate,
	setStartDate,
	setEndDate,
	isSimple = false,
}: {
	isStart: boolean;
	date: number | null | undefined;
	otherDate: number | null | undefined;
	setStartDate: (date: number | undefined | null) => void;
	setEndDate: (date: number | undefined | null) => void;
	isSimple?: boolean;
}) => {
	const inputRef = useRef<any>(null);
	const [inputValue, setInputValue] = useState('');
	const [prevValue, setPrevValue] = useState('');
	const dispatch = useDispatch();

	useEffect(() => {
		if (date) {
			setInputValue(format(new Date(date), 'dd/MM/yyyy'));
		} else if (date === null) {
			setInputValue('');
		}
	}, [date]);

	useLayoutEffect(() => {
		if (isSimple) {
			inputRef.current.focus();
		}
	}, [isSimple]);

	const isValidDate = (dateString: string) => {
		const regex = /^\d{2}\/\d{2}\/\d{4}$/;
		if (!regex.test(dateString)) return false;

		const [day, month, year] = dateString.split('/').map(Number);
		const date = new Date(year, month - 1, day);

		return (
			date.getFullYear() === year &&
			date.getMonth() + 1 === month &&
			date.getDate() === day
		);
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputVal = e.target.value.replace(/[^0-9/]/g, '');
		const slashCount = (inputVal.match(/\//g) || []).length;

		const splitted = inputVal.split('/');
		let day = splitted[0] || (inputVal.startsWith('/') ? '' : null);
		let month = splitted[1] || (slashCount === 2 ? '' : null);
		let year =
			splitted[2] || (inputVal.endsWith('/') && slashCount === 2 ? '' : null);

		const retreivedYear = year;

		if (year) {
			if (
				year.length >= 4 &&
				!year.startsWith('20') &&
				prevValue.split('/')[2].length < year.length
			) {
				year = '';
			} else if (year.startsWith('20')) {
				year = year.slice(2);
			} else {
				year = year.slice(1);
			}
		}

		// prevents from deleting the slashes
		const prevSplitted = prevValue.split('/');
		const prevDay = prevSplitted[0];
		const prevMonth = prevSplitted[1];
		const prevYear = prevSplitted[2];

		if (prevValue.length - inputVal.length > 1) {
			if (prevSplitted.length !== splitted.length) {
				setInputValue(inputVal.length === 1 ? inputVal : '');
				setPrevValue(inputVal.length === 1 ? inputVal : '');
				return;
			}
		}
		if (prevSplitted.length > splitted.length) {
			day = prevDay;
			month = prevMonth;
			year = prevYear ? prevYear.slice(2, 4) : null;
		}
		if (prevValue.length - inputVal.length > 1) {
			if (year !== null && prevYear.length - year.length > 1) {
				year = '';
			}
		}

		// set day month and year
		let formattedValue = '';

		if (day !== null) {
			if (day.length === 1) {
				if (parseInt(day) > 3 && !prevDay.startsWith('0')) {
					formattedValue += '0' + day + '/';
					setTimeout(() => {
						if (inputRef.current?.selectionStart) {
							inputRef.current.selectionStart = 3;
							inputRef.current.selectionEnd = 3;
						}
					}, 0);
				} else {
					formattedValue += day + (month !== null ? '/' : '');
				}
			} else if (day.length === 2) {
				const minValue = Math.min(parseInt(day), 31);
				formattedValue += (minValue < 10 ? '0' + minValue : minValue) + '/';

				if (parseInt(day) > 31) {
					setTimeout(() => {
						if (inputRef.current?.selectionStart) {
							inputRef.current.selectionStart = 2;
							inputRef.current.selectionEnd = 2;
						}
					}, 0);
				}
			} else if (day.length > 2) {
				formattedValue += prevDay + '/';
			} else if (day === '') {
				formattedValue += '/';
			}
		}

		if (month !== null) {
			if (month.length === 1) {
				if (parseInt(month) > 1 && !prevMonth.startsWith('0')) {
					formattedValue += '0' + month + '/20';
					setTimeout(() => {
						if (inputRef.current?.selectionStart) {
							// const thisDay = formattedValue.split('/')[0];
							// const offset =
							// 	thisDay.length === 0 ? 6 : thisDay.length === 1 ? 7 : 8;

							// inputRef.current.selectionStart = offset;
							// inputRef.current.selectionEnd = offset;
							inputRef.current.selectionStart = 10;
							inputRef.current.selectionEnd = 10;
						}
					}, 0);
				} else {
					formattedValue += month + (year !== null ? '/20' : '');
				}
			} else if (month.length === 2) {
				const minValue = Math.min(parseInt(month), 12);
				formattedValue += (minValue < 10 ? '0' + minValue : minValue) + '/20';

				if (parseInt(month) > 12) {
					setTimeout(() => {
						if (inputRef.current?.selectionStart) {
							inputRef.current.selectionStart = 5;
							inputRef.current.selectionEnd = 5;
						}
					}, 0);
				}

				if (prevMonth.length === 1 && month.length === 2) {
					setTimeout(() => {
						if (inputRef.current?.selectionStart) {
							inputRef.current.selectionStart = 10;
							inputRef.current.selectionEnd = 10;
						}
					}, 0);
				}
			} else if (month.length > 2) {
				formattedValue += prevMonth + '/20';
			} else if (month === '' || inputVal.includes('//')) {
				formattedValue += '/20';
			}
		}

		if (year) {
			let str = '';
			const minValue = Math.min(parseInt(year), 99);
			if (minValue.toString().length < year.length) {
				const diff = year.length - minValue.toString().length;
				for (let i = 0; i < diff; i++) {
					str += year[i] === '0' ? '0' : '';
				}
			}
			str += minValue;
			formattedValue += str;
		}

		// if the last day is 1 and we add a new one, we jump the cursor to the next one
		if (prevDay && prevDay.length === 1 && day && day.length === 2) {
			setTimeout(() => {
				if (inputRef.current?.selectionStart) {
					inputRef.current.selectionStart += 1;
					inputRef.current.selectionEnd = inputRef.current.selectionStart;
				}
			}, 0);
		}
		if (prevMonth && prevMonth.length === 1 && month && month.length === 2) {
			setTimeout(() => {
				if (inputRef.current?.selectionStart) {
					inputRef.current.selectionStart += 1;
					inputRef.current.selectionEnd = inputRef.current.selectionStart;
				}
			}, 0);
		}

		// if we delete a slash, we delete the character before
		if (prevSplitted.length > splitted.length) {
			let slashPosition = 0;
			for (let i = 0; i < prevValue.length; i++) {
				if (prevValue[i] === '/') {
					if (inputVal[i] !== '/') {
						slashPosition = i;
						break;
					}
				}
			}

			formattedValue =
				slashPosition === 0 || (slashPosition === 1 && prevValue[0] === '/')
					? formattedValue
					: formattedValue.slice(
							0,
							slashPosition - (prevValue[slashPosition - 1] === '/' ? 2 : 1)
					  ) +
					  formattedValue.slice(
							slashPosition - (prevValue[slashPosition - 1] === '/' ? 1 : 0)
					  );

			setTimeout(() => {
				if (inputRef.current?.selectionStart) {
					inputRef.current.selectionStart = slashPosition - 1;
					inputRef.current.selectionEnd = inputRef.current.selectionStart;
				}
			}, 0);
		}

		// if delete the '20' for year, we delete the last month
		if (!retreivedYear?.startsWith('20')) {
			if (retreivedYear?.startsWith('0')) {
				formattedValue =
					formattedValue.slice(0, formattedValue.lastIndexOf('/') - 1) +
					formattedValue.slice(formattedValue.lastIndexOf('/'));
			} else if (retreivedYear?.startsWith('2')) {
				if (
					prevValue.split('/')[2].startsWith('20') &&
					prevValue.length - inputVal.length > 0
				) {
					if (
						retreivedYear ===
						prevSplitted[2].slice(0, 1) + prevSplitted[2].slice(2, 4)
					) {
						formattedValue =
							formattedValue.slice(0, formattedValue.lastIndexOf('/') - 1) +
							formattedValue.slice(formattedValue.lastIndexOf('/'));
					} else {
						formattedValue = prevSplitted[0] + '/' + prevSplitted[1] + '/20';
					}
				} else if (!(year !== null && prevYear.length - year.length > 2)) {
					formattedValue =
						formattedValue.slice(0, formattedValue.lastIndexOf('/') - 1) +
						formattedValue.slice(formattedValue.lastIndexOf('/'));
				}
			}
		}

		// remove last slash
		if (
			(formattedValue.endsWith('/') &&
				formattedValue.split('/')[1].length === 1) ||
			(formattedValue.endsWith('/') &&
				slashCount === 1 &&
				formattedValue.split('/')[0].length === 1)
		) {
			formattedValue = formattedValue.slice(0, formattedValue.length - 1);
		}
		if (
			formattedValue.endsWith('/20') &&
			formattedValue.split('/')[1].length === 1
		) {
			formattedValue = formattedValue.slice(0, formattedValue.length - 3);
		}
		if (formattedValue.endsWith('//20')) {
			formattedValue = formattedValue.slice(0, formattedValue.length - 3);
		}
		if (formattedValue.endsWith('/') && formattedValue.length === 2) {
			formattedValue = formattedValue.slice(0, 1);
		}
		if (formattedValue === '/') {
			formattedValue = '';
		}

		setInputValue(formattedValue);
		setPrevValue(formattedValue);

		if (formattedValue) {
			const [day, month, year] = formattedValue.split('/').map(Number);
			const newDate = new Date(year, month - 1, day);
			if (isValidDate(formattedValue)) {
				if (isStart) {
					if (otherDate && otherDate < newDate.getTime()) {
						setStartDate(otherDate);
						setEndDate(newDate.getTime());
					} else {
						setStartDate(newDate.getTime());
						document.getElementById('endDateSelectorInput')?.focus();
					}
				} else {
					if (otherDate && otherDate > newDate.getTime()) {
						setStartDate(newDate.getTime());
						document.getElementById('endDateSelectorInput')?.focus();
					} else {
						setEndDate(newDate.getTime());
					}
				}
			} else {
				if (isStart) {
					setStartDate(undefined);
				} else {
					setEndDate(undefined);
				}
			}
		}
	};

	const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		e.target.select();
		dispatch(isStart ? setStartModify(true) : setEndModify(true));
	};

	const handleBlur = () => {
		setTimeout(() => {
			dispatch(isStart ? setStartModify(false) : setEndModify(false));
		}, 300);
	};

	const formattedDate = (formattedValue: string) => {
		const placeholder = ['j', 'j', '/', 'm', 'm', '/', '2', '0', 'a', 'a'];
		const inputChars = formattedValue.split('');

		const splitted = formattedValue.split('/');
		const day = splitted[0];
		const month = splitted[1];

		if (splitted.length > 2) {
			if (month.length === 1) {
				placeholder.splice(3, 1);
			}
			if (month.length === 0) {
				placeholder.splice(3, 2);
			}
		}
		if (splitted.length > 1) {
			if (day.length === 1) {
				placeholder.shift();
			}
			if (day.length === 0) {
				placeholder.shift();
				placeholder.shift();
			}
		}

		let combined = placeholder.map((char, index) => {
			return inputChars[index] ? inputChars[index] : char;
		});

		return combined.join('');
	};

	return (
		<div className={`${styles.dateSelector} ${isSimple ? styles.simple : ''}`}>
			<div className={styles.title}>
				{!isSimple ? `Date de ${isStart ? 'début' : 'fin'}` : <br />}
			</div>
			<span className={styles.fakePlaceholder}>
				{formattedDate(inputValue)}
			</span>
			<input
				id={!isStart ? 'endDateSelectorInput' : ''}
				type='text'
				inputMode='numeric'
				ref={inputRef}
				placeholder='jj/mm/20aa'
				value={inputValue}
				maxLength={10}
				onChange={handleChange}
				onFocus={handleFocus}
				onBlur={handleBlur}
			/>
		</div>
	);
};
