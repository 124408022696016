import { useMemo, useState, useEffect, useRef } from 'react';
import Checklist from './Checklist/Checklist';
import { useDeviceType } from '../useDeviceType';
import { useNavigation } from '../NavigationContext';
import {
	Checklist as ChecklistType,
	Chapter as ChapterType,
	Question as QuestionType,
	Rule,
	Response,
} from '../types';
import {
	useGetChecklistQuery,
	useGetAnswerQuery,
	useGetResponsesQuery,
} from '../store/api';
import { isAnswered } from './Checklist/checklistUtils';
import { ReactComponent as MailIcon } from './icons/mail.svg';
import { ReactComponent as ArrowBackIcon } from './icons/arrow_back.svg';
import Comments from './Comments';
import ResponsesMenu from './Response/ResponsesMenu';
import QuestionMenu from './Response/QuestionMenu';
import ScrollableMobileResponse from './Response/ScrollableMobileResponse';
import Navbar from './Navbar/Navbar';
import PdfGenerator from './Pdf/PdfGenerator';
import config from '../config';
import styles from './Checklist/Checklist.module.scss';

const ResponseChecklist = ({
	id,
	response,
	responses,
}: {
	id: string;
	response: Response & { folderId: string };
	responses: Response[];
}) => {
	const { navigation, setNavigation } = useNavigation();
	const { data } = useGetAnswerQuery(response._id);
	const { isTypeOrLarger } = useDeviceType();
	const [commentsOn, setCommentsOn] = useState(false);
	const [isAutoNavigationDisabled, setIsAutoNavigationDisabled] =
		useState(true);
	const [copied, setCopied] = useState(false);
	const [selectedComment, setSelectedComment] = useState<{
		roomId: string;
		entityType: 'checklist' | 'chapter' | 'question';
		entityId: string;
	}>({
		roomId: `checklist-${response.state._id}`,
		entityType: 'checklist',
		entityId: response.state._id,
	});

	const isFirstRender = useRef(true);

	useEffect(() => {
		if (navigation.startsWith('question')) {
			setSelectedComment({
				roomId: navigation,
				entityType: 'question',
				entityId: navigation.split('-')[2],
			});
		}
	}, [navigation, response.state._id]);

	const responseWithRulesApplied = useMemo(() => {
		const toMaskQuestions: string[] = [];

		response.state.chapters?.forEach((chapter: ChapterType) => {
			chapter.questions?.forEach((question: QuestionType) => {
				if (data && data[question.questionId]) {
					if (question.rules) {
						question.rules.forEach((rule: Rule) => {
							if (rule.condition) {
								// If the answer is
								if (
									(rule.value as string[]).includes(
										data[question.questionId] as any
									) // the chosen option
								) {
									if (!rule.action) {
										// then hide
										rule.targetQuestionIds?.forEach((targetQuestionId) => {
											toMaskQuestions.push(targetQuestionId);
										});
									}
								} else {
									// not the chosen option
									if (rule.action) {
										// then hide
										rule.targetQuestionIds?.forEach((targetQuestionId) => {
											toMaskQuestions.push(targetQuestionId);
										});
									}
								}
							} else {
								// If the answer is
								if (
									(rule.value as string[]).includes(
										data[question.questionId] as any
									) // the chosen option
								) {
									if (rule.action) {
										// then hide
										rule.targetQuestionIds?.forEach((targetQuestionId) => {
											toMaskQuestions.push(targetQuestionId);
										});
									}
								} else {
									// not the chosen option
									if (!rule.action) {
										// then hide
										rule.targetQuestionIds?.forEach((targetQuestionId) => {
											toMaskQuestions.push(targetQuestionId);
										});
									}
								}
							}
						});
					}
				} else {
					if (!isAnswered(question.questionId)) {
						question.rules?.forEach((rule: Rule) => {
							rule.targetQuestionIds?.forEach((targetQuestionId) => {
								toMaskQuestions.push(targetQuestionId);
							});
						});
					}
				}
			});
		});

		const filteredChapter = response.state?.chapters
			?.map((chapter: ChapterType) => {
				const newQuestions = chapter.questions?.filter(
					(question: QuestionType) =>
						!toMaskQuestions.includes(question.questionId)
				);
				return { ...chapter, questions: newQuestions };
			})
			.filter((chapter) => chapter.questions?.length);

		return { ...response.state, chapters: filteredChapter };
	}, [response, data]);

	useEffect(() => {
		if (
			isFirstRender.current &&
			isTypeOrLarger('md') &&
			responseWithRulesApplied?.chapters?.length > 0
		) {
			const firstChapter = responseWithRulesApplied.chapters[0];
			if (firstChapter.questions?.length > 0) {
				const firstQuestionId = firstChapter.questions[0].questionId;
				setNavigation(`question-${firstChapter.chapterId}-${firstQuestionId}`);
			}
			isFirstRender.current = false;
		}
	}, [isTypeOrLarger, responseWithRulesApplied, setNavigation]);

	const handleCopyLink = () => {
		const newInviteLink = `${config.baseUrl
			.replace('/api', '')
			.replace('localhost:3000', 'localhost:3001')}/?response=${response._id}`;
		navigator.clipboard.writeText(newInviteLink);
		setCopied(true);
		setTimeout(() => setCopied(false), 2000);
	};

	if (!data) return null;

	return (
		<div className={styles.responseContainer}>
			<Navbar
				before={
					<button
						className={styles.backArrow}
						onClick={() => setNavigation(`folder-${response.folderId}`)}
					>
						<ArrowBackIcon />
					</button>
				}
				after={
					<div className={styles.flex}>
						<button onClick={handleCopyLink} className={styles.primaryButton}>
							<MailIcon />
							{copied ? 'Copié !' : 'Inviter à remplir'}
						</button>
						<PdfGenerator response={response} id={id} />
					</div>
				}
			/>
			<div
				style={{ height: 'calc(100% - 4.5rem)', gap: 0 }}
				className={styles.flex}
			>
				{isTypeOrLarger('md') && (
					<ResponsesMenu
						currentAnswers={data}
						responses={responses}
						chapters={responseWithRulesApplied.chapters}
						checklistId={id}
					/>
				)}

				{isTypeOrLarger('md') ? (
					<ScrollableMobileResponse
						id={id}
						data={responseWithRulesApplied as ChecklistType}
						values={data}
						responseId={response._id}
						isAutoNavigationDisabled={isAutoNavigationDisabled}
						setIsAutoNavigationDisabled={setIsAutoNavigationDisabled}
						setCommentsOn={setCommentsOn}
					/>
				) : (
					<Checklist
						id={id}
						data={responseWithRulesApplied as ChecklistType}
						values={data}
						responseId={response._id}
						mode="response"
						navigation={navigation}
						setNavigation={setNavigation}
					/>
				)}

				{isTypeOrLarger('md') &&
					(commentsOn ? (
						<Comments
							roomId={selectedComment.roomId}
							entityType={selectedComment.entityType}
							entityId={selectedComment.entityId}
							close={() => setCommentsOn(false)}
							noBorder
						/>
					) : (
						<QuestionMenu
							data={responseWithRulesApplied}
							values={data}
							setIsAutoNavigationDisabled={setIsAutoNavigationDisabled}
						/>
					))}
			</div>
		</div>
	);
};

export default function ChecklistContainer({
	id,
	response,
}: {
	id: string;
	response?: Response;
}) {
	const { navigation, setNavigation } = useNavigation();
	const { data } = useGetChecklistQuery(id);
	const { data: responses } = useGetResponsesQuery(id);

	return (
		<div style={{ height: '100%' }}>
			{response ? (
				<ResponseChecklist
					id={id}
					response={{ ...response, folderId: data?.folderId || '' }}
					responses={responses || []}
				/>
			) : (
				<>
					{data && (
						<Checklist
							id={id}
							data={{ ...data.state, folderId: data.folderId }}
							mode="edit"
							navigation={navigation}
							setNavigation={setNavigation}
						/>
					)}
				</>
			)}
		</div>
	);
}
