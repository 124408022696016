import {io} from 'socket.io-client';
import config from '../config';

const socket = io(config.socketUrl, {
	auth: {
		token: localStorage.getItem('token'),
	},
	transports: ['websocket'],
});

export default socket;
