import React from 'react';
// import Avatar from 'components/Avatar/Avatar';
import { ReactComponent as LogoIcon } from '../icons/soopcl.svg';
// import { ReactComponent as NotificationIcon } from '../icons/notifications.svg';
// import { ReactComponent as SettingsIcon } from '../icons/settings.svg';
// import { useGetMeQuery } from 'store/api';
import styles from './Navbar.module.scss';

export default function Navbar({
	before,
	after,
	border = false,
}: {
	before?: React.ReactNode;
	after?: React.ReactNode;
	border?: boolean;
}) {
	// const { data: user } = useGetMeQuery();

	return (
		<div className={`${styles.navbar} ${border ? styles.border : ''}`}>
			{before ? before : <LogoIcon />}
			{/* <div className={styles.flex}>
				<NotificationIcon />
				<SettingsIcon />
				<div className={styles.avatarContainer}>
					<Avatar name={`${user?.firstName} ${user?.lastName}`} />
				</div>
			</div> */}
			{after ? after : null}
		</div>
	);
}
