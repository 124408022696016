import { useGetFolderQuery, useGetFoldersQuery } from '../../store/api';
import { useNavigation } from '../../NavigationContext';
import socket from '../../store/socket';
import { Checklist as ChecklistType } from '../../types';
import CoverImage from 'components/shared/CoverImage/CoverImage';
import { ReactComponent as AddIcon } from '../icons/add.svg';
import { ReactComponent as ChecklistIcon } from '../icons/checklist.svg';
import { ReactComponent as LeftArrowIcon } from '../icons/arrow_back.svg';
import styles from './Home.module.scss';
import Navbar from 'components/Navbar/Navbar';

export default function Folder() {
	const { navigation, setNavigation, setOpenedChecklistId } = useNavigation();
	const { data: folders } = useGetFoldersQuery();
	const { data: checklists } = useGetFolderQuery(
		navigation.split('-')[1] ?? '',
		{
			skip: !navigation.split('-')[1],
		}
	);

	const handleNavigateToChecklist = (checklistId: string) => {
		setNavigation(`checklist-${checklistId}`);
		setOpenedChecklistId(checklistId);
	};

	const handleCreateChecklist = () => {
		socket.emit('createChecklist', {
			folderId: navigation.split('-')[1],
			title: 'Nouvelle checklist',
		});
	};

	return (
		<div className={styles.home}>
			<Navbar
				before={<LeftArrowIcon onClick={() => setNavigation('home')} />}
			/>
			<div className={styles.homeContent}>
				<div className={styles.flex}>
					<div className={styles.homeItemContainer}>
						<ChecklistIcon />
					</div>
					<div className={styles.homeItemText}>
						{`${checklists?.length} Checklist${
							checklists?.length && checklists?.length === 1 ? '' : 's'
						}`}
						<h2>
							{
								folders?.find(
									(folder) => folder._id === navigation.split('-')[1]
								)?.name
							}
						</h2>
					</div>
					<div className={styles.homeItemButtonContainer}>
						<button
							onClick={handleCreateChecklist}
							className={styles.iconButton}
						>
							<AddIcon fill="#FFFFFF" />
							Créer
						</button>
					</div>
				</div>

				<div className={styles.content}>
					{checklists?.length && checklists?.length > 0 && (
						<div className={styles.checklistItemContainer}>
							{checklists?.map((checklist: ChecklistType) => (
								<div
									key={checklist._id}
									className={styles.checklistItem}
									onClick={() => handleNavigateToChecklist(checklist._id)}
								>
									<CoverImage
										big
										id={checklist._id}
										coverImage={checklist.coverImage}
									/>
									<div className={styles.checklistInfo}>
										<h5>{checklist.title}</h5>
										{/* <p>{`${checklists?.length} checklist${
					checklists?.length === 1 ? '' : 's'
				}`}</p> */}
									</div>
								</div>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
