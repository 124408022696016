import React from 'react';
import { Upload } from '../../Upload';
import { Question as QuestionType } from '../../../types';
import styles from './Media.module.scss';

export default function Media({
	question,
	value,
	onChange,
}: {
	question: QuestionType;
	value: string[];
	onChange: (value: string[]) => void;
}) {
	return (
		<div className={styles.mediaContainer}>
			<Upload
				mode={question.media?.mediaType || 'photo'}
				maxFiles={question.media?.maxFiles || 1}
				value={value}
				onChange={onChange}
			/>
		</div>
	);
}
