import { useEffect, useMemo, useState } from 'react';
import socket from '../../store/socket';
import { ReactComponent as MailIcon } from '../icons/mail.svg';
import { ReactComponent as UndoIcon } from '../icons/undo.svg';
import { ReactComponent as RedoIcon } from '../icons/redo.svg';
import { ReactComponent as ArrowDownIcon } from '../icons/arrow_down.svg';
import { ReactComponent as ShareIcon } from '../icons/share.svg';
import { ReactComponent as PreviousIcon } from '../icons/previous.svg';
import { ReactComponent as NextIcon } from '../icons/next.svg';
import { ReactComponent as TranslateIcon } from '../icons/translate.svg';
import { ReactComponent as ExclamationMarkIcon } from '../icons/exclamation_mark.svg';
import { ReactComponent as PlayCircle } from '../icons/play_circle.svg';
import { ReactComponent as PlayCircleFilled } from '../icons/play_circle_filled.svg';
import { ReactComponent as CheckIcon } from '../icons/check_circle.svg';
import { ReactComponent as LeftArrowIcon } from '../icons/arrow_back.svg';
import {
	Checklist as ChecklistType,
	Chapter as ChapterType,
	AnswersResponse,
	Question as QuestionType,
	Response,
} from '../../types';
import {
	useGetEditingChecklistQuery,
	useGetEditingResponseQuery,
	useGetResponsesQuery,
} from '../../store/api';
import Comments from '../Comments';
import { useMeQuery } from '../../store/userApi';
import InvitePopup from './InvitePopup/InvitePopup';
import Chapter from './Chapter';
import Question from './Question';
import DraggableNavigation from './DraggableNavigation';
import Avatar from '../Avatar/Avatar';
import styles from './Checklist.module.scss';
import { Upload } from '../Upload';
import TitleInput from '../TitleInput/TitleInput';
import LateralPannel from './LateralPanel';
import { useDeviceType } from 'useDeviceType';
import { useNavigation } from '../../NavigationContext';
import Navbar from 'components/Navbar/Navbar';

export default function Checklist({
	id,
	responseId,
	mode,
	data,
	values,
	navigation,
	setNavigation,
}: {
	id: string;
	responseId?: string;
	mode: 'edit' | 'response';
	data: ChecklistType;
	values?: AnswersResponse;
	navigation: string;
	setNavigation: (navigation: string) => void;
}) {
	const { setOpenedResponse, setOpenedChecklistId } = useNavigation();
	const { isTypeOrLarger } = useDeviceType();
	const [commentMode, setCommentMode] = useState<'comments' | 'views' | null>(
		null
	);
	const [openInvitePopup, setOpenInvitePopup] = useState(false);

	const { data: meData } = useMeQuery({});
	const { data: editingChecklist } = useGetEditingChecklistQuery(id, {
		skip: mode === 'response',
	});
	const { data: editingResponse } = useGetEditingResponseQuery(
		responseId || '',
		{
			skip: mode === 'edit',
		}
	);
	const { data: responses } = useGetResponsesQuery(id);

	const [selectedComment, setSelectedComment] = useState<{
		roomId: string;
		entityType: 'checklist' | 'chapter' | 'question';
		entityId: string;
	}>({
		roomId: `checklist-${data._id}`,
		entityType: 'checklist',
		entityId: data._id,
	});

	useEffect(() => {
		if (navigation !== 'checklist') {
			setSelectedComment({
				roomId: navigation,
				entityType: navigation.split('-')[0] as 'chapter' | 'question',
				entityId: navigation.split('-')[1],
			});
		} else {
			setSelectedComment({
				roomId: `checklist-${data._id}`,
				entityType: 'checklist',
				entityId: data._id,
			});
		}
	}, [navigation, data._id]);

	useEffect(() => {
		const roomId = mode === 'edit' ? id : responseId;
		const joinEvent = mode === 'edit' ? 'joinChecklist' : 'joinResponse';
		const leaveEvent = mode === 'edit' ? 'leaveChecklist' : 'leaveResponse';

		if (!socket.hasListeners(roomId as string)) {
			socket.emit(joinEvent, roomId);
		}

		return () => {
			if (socket.hasListeners(roomId as string)) {
				socket.emit(leaveEvent, roomId);
			}
		};
	}, [id, responseId, mode]);

	const handleUndo = () => {
		if (mode === 'edit') {
			socket.emit('undo', { checklistId: id });
		} else {
			socket.emit('undoAnswer', { responseId: responseId });
		}
	};

	const handleRedo = () => {
		if (mode === 'edit') {
			socket.emit('redo', { checklistId: id });
		} else {
			socket.emit('redoAnswer', { responseId: responseId });
		}
	};

	const question = useMemo(() => {
		const chapter = data.chapters.find((chapter: ChapterType) =>
			chapter.questions?.some(
				(question: QuestionType) =>
					question.questionId === navigation.split('-')[2]
			)
		);
		if (!chapter) return null;
		return (chapter as ChapterType).questions.find(
			(question: QuestionType) =>
				question.questionId === navigation.split('-')[2]
		);
	}, [data, navigation]);

	return (
		<div className={styles[mode]} style={{ position: 'relative' }}>
			{mode === 'edit' ? (
				<>
					<Navbar
						border
						before={
							<div className={styles.flex}>
								<button className={styles.backArrow}>
									<LeftArrowIcon
										onClick={() => {
											if (setOpenedChecklistId) {
												setOpenedChecklistId(null);
												setNavigation(`folder-${data.folderId}`);
											}
										}}
									/>
								</button>
							</div>
						}
						after={
							<div className={styles.flex}>
								{editingChecklist
									?.filter((user) => user.userId !== meData?._id)
									.map((user) => (
										<Avatar
											name={`${user.firstName} ${user.lastName}`}
											key={user.userId}
										/>
									))}
								{editingResponse
									?.filter((user) => user.userId !== meData?._id)
									.map((user) => (
										<Avatar
											small
											name={`${user.firstName} ${user.lastName}`}
											key={user.userId}
										/>
									))}
								<div className={styles.verticalSeparator} />
								<button className={styles.noBorderButton} onClick={handleUndo}>
									<UndoIcon />
								</button>
								<button className={styles.noBorderButton} onClick={handleRedo}>
									<RedoIcon />
								</button>
								<button
									onClick={() => setOpenInvitePopup(true)}
									className={styles.primaryButton}
								>
									<MailIcon />
									Inviter à remplir
								</button>
								{openInvitePopup && (
									<InvitePopup close={() => setOpenInvitePopup(false)} />
								)}
							</div>
						}
					/>

					<div className={styles.content}>
						<DraggableNavigation
							checklistId={id}
							title={data.title || 'Untitled Checklist'}
							chapters={data.chapters || []}
							firstResponse={responses?.[0]}
						/>
						{navigation.startsWith('checklist') && (
							<div className={styles.checklist}>
								<TitleInput
									mode={'title'}
									placeholder="Saisir le titre de la checklist"
									title={data.title}
									onChange={(value) =>
										socket.emit('updateChecklist', {
											checklistId: id,
											title: value,
										})
									}
								/>
								<div className={styles.separator} />
								<div className={styles.imgContainer}>
									<img src={data.coverImage} alt="cover" />
								</div>
								<Upload
									value={data.coverImage || []}
									onChange={(value) =>
										socket.emit('updateChecklist', {
											checklistId: id,
											coverImage: value,
										})
									}
									mode="photo"
									buttonLabel="Ajouter une image de couverture"
									hide
									replace
								/>
							</div>
						)}
						{data?.chapters?.map(
							(chapter: ChapterType) =>
								navigation.includes(chapter.chapterId) && (
									<div key={chapter.chapterId} className={styles.settings}>
										<Chapter
											key={chapter.chapterId}
											chapter={chapter}
											checklistId={id}
											responseId={responseId}
											mode={mode}
											navigation={navigation}
											setNavigation={setNavigation}
											values={values}
											checklist={data}
											commentMode={commentMode}
											setCommentMode={setCommentMode}
										/>
									</div>
								)
						)}
						{commentMode === 'comments' ? (
							<Comments
								roomId={selectedComment.roomId}
								entityType={selectedComment.entityType}
								entityId={selectedComment.entityId}
								close={() => setCommentMode(null)}
							/>
						) : (
							navigation.startsWith('question') && (
								<LateralPannel
									checklist={data}
									id={id}
									navigation={navigation}
									close={() => setCommentMode(null)}
								/>
							)
						)}
					</div>
				</>
			) : (
				<>
					{!isTypeOrLarger('md') ? (
						<>
							<div className={styles.navigation}>
								<div className={styles.header}>
									<ArrowDownIcon />
									<ShareIcon />
								</div>
								<div className={styles.spacedFlex}>
									<PreviousIcon
										onClick={() => {
											if (responses) {
												const index = responses.findIndex(
													(response) => response._id === responseId
												);
												if (index > 0) {
													setOpenedResponse &&
														setOpenedResponse(responses[index - 1] as Response);
												}
											}
										}}
									/>
									<img src={data.coverImage} alt="cover" />
									<NextIcon
										onClick={() => {
											if (responses) {
												const index = responses.findIndex(
													(response) => response._id === responseId
												);
												if (index < responses.length - 1) {
													setOpenedResponse &&
														setOpenedResponse(responses[index + 1] as Response);
												}
											}
										}}
									/>
								</div>
								<div className={styles.title}>
									<h4>{data.title || 'Untitled Checklist'}</h4>
									<div className={styles.tabBarItems}>
										{/* Pas encore commencée */}( Réponse n={' '}
										{(responses?.findIndex(
											(response) => response._id === responseId
										) || 0) + 1}
										)
									</div>
								</div>
								<div className={styles.navButton}>
									<button className={styles.greyButton}>
										<TranslateIcon /> Traduire
									</button>
									<button className={styles.greyButton}>
										<ExclamationMarkIcon />
										Signaler un problème
									</button>
								</div>
							</div>
							<div className={styles.content}>
								<h5>Chapitres</h5>
								{data?.chapters
									?.sort((a: ChapterType, b: ChapterType) => a.index - b.index)
									?.map((chapter: ChapterType) => (
										<div
											onClick={() =>
												setNavigation(
													`question-${chapter.chapterId}-${chapter.questions[0].questionId}`
												)
											}
											className={styles.spacedFlex}
											key={chapter.chapterId}
										>
											<div className={styles.column}>
												<div className={styles.button}>{chapter.title}</div>
												<div className={styles.tabBarItems}>
													Chapitre{' '}
													{data.chapters.findIndex(
														(thisChapter: ChapterType) =>
															thisChapter.chapterId === chapter.chapterId
													) + 1}
												</div>
											</div>
											{chapter.questions?.every(
												(question) => values?.[question.questionId]
											) ? (
												<CheckIcon />
											) : (
												<PlayCircleFilled />
											)}
										</div>
									))}
							</div>
							<div className={styles.footer}>
								<button
									onClick={() => {
										if (
											Array.isArray(data.chapters) &&
											data.chapters.length > 0
										) {
											const firstUnansweredQuestionId = data.chapters
												.flatMap(
													(chapter: ChapterType) => chapter.questions || []
												)
												.find(
													(question) =>
														question.questionId &&
														!values?.[question.questionId]
												)?.questionId;

											if (firstUnansweredQuestionId) {
												setNavigation(
													`question-${
														firstUnansweredQuestionId.split('-')[0]
													}-${firstUnansweredQuestionId}`
												);
											} else {
												const firstChapter = (
													data.chapters as ChapterType[]
												)[0];
												if (
													firstChapter &&
													Array.isArray(firstChapter.questions) &&
													firstChapter.questions.length > 0
												) {
													const firstQuestionId =
														firstChapter.questions[0].questionId;
													if (firstQuestionId) {
														setNavigation(
															`question-${firstChapter.chapterId}-${firstQuestionId}`
														);
													}
												}
											}
										}
									}}
									className={styles.primaryButton}
								>
									{Array.isArray(data.chapters) &&
									data.chapters.length > 0 &&
									data.chapters
										.flatMap((chapter: ChapterType) => chapter.questions || [])
										.every(
											(question) =>
												question.questionId && values?.[question.questionId]
										)
										? 'Commencer'
										: 'Reprendre'}
									<PlayCircle />
								</button>
							</div>
						</>
					) : (
						<div className={styles.emptyPlaceholder} />
					)}
					{navigation.startsWith('question') && (
						<Question
							question={question as QuestionType}
							checklistId={id}
							responseId={responseId}
							navigation={navigation}
							setNavigation={setNavigation}
							mode={'response'}
							values={values}
							checklist={data}
							commentMode={commentMode}
							setCommentMode={setCommentMode}
						/>
					)}
				</>
			)}
		</div>
	);
}
